import { Client } from "../../api/api";
import * as ClientType from "../types/clientType";

// COMPANY PROFILE
export const ClientAPI_Company_Profile_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      dispatch({ type: ClientType.LOADING_COMPANY_PROFILE });

      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Company_Profile_Get();
      dispatch({
        type: ClientType.GET_COMPANY_PROFILE,
        data: result.data?.data,
      });

      return result;
    } catch (e) {
      dispatch({ type: ClientType.FAILED_COMPANY_PROFILE, error: e });
      throw e;
    }
  };

export const ClientAPI_Check_Token =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Check_Token();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Update_Next_Link =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Update_Next_Link();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Menus_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Menus_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Referral_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Referral_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Client_Preference_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Client_Preference_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Notification_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Notification_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Notification_Visit =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Notification_Visit();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Verify_OTP =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Verify_OTP();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Resend_OTP =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Resend_OTP();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Terms_And_Conditions =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Terms_And_Conditions();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Terms_And_Conditions_Agreed =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Terms_And_Conditions_Agreed();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Terms_Docu_Sign =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Terms_Docu_Sign();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Download_Resume =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Download_Resume_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Download_Invoice =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Download_Invoice_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Login =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Login();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Logout =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Logout();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Register =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Register();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Forgot_Password =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Forgot_Password();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Reset_Password =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Reset_Password();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Configuration_Payment =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Configuration_Payment();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Save_Payment =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Save_Payment();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Job_Positions =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Job_Positions();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Job_Experiences =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Job_Experiences();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Job_Timezones =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Job_Timezones();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Job_Shift =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Job_Shift();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Job_Budgets =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Job_Budgets();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Job_Save =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Job_Save();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Applicant_Filters =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Applicant_Filters();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_ES_Applicant_Filters =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_ES_Applicant_Filters();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Applicant_Search =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Applicant_Search();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_ES_Applicant_Search =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_ES_Applicant_Search();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Applicant_SearchInput =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Applicant_SearchInput();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_ES_Applicant_SearchInput =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_ES_Applicant_SearchInput();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Applicant_Resume =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Applicant_Resume();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Applicant_Interested =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Applicant_Interested();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Candidate_List_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Candidate_List_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Applicant_Resume_Public =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Applicant_Resume_Public();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Hired_Applicants_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Hired_Applicants_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Hired_Applicant_Information_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Hired_Applicant_Information_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Hired_Applicant_Documents_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Hired_Applicant_Documents_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Profile_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Profile_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Profile_Save =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Profile_Save();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Industry_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Industry_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Country_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Country_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Region_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Region_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_State_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_State_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_City_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_City_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_City_By_Postal_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_City_By_Postal_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Office_Detail_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Office_Detail_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Contact_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Contact_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Contact_Delete =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Contact_Delete();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Documents_And_Photos_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Documents_And_Photos_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Documents_And_Photos_Save =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Documents_And_Photos_Save();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Documents_And_Photos_Delete =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Documents_And_Photos_Delete();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Sow_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Sow_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Sow_Sign =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Sow_Sign();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Appraisal_Questionnaire_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Appraisal_Questionnaire_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Roles_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Roles_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Roles_Save =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Roles_Save();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Roles_View =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Roles_View();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Roles_Update =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Roles_Update();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Roles_Delete =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Roles_Delete();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Roles_Archives_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Roles_Archives_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Roles_Archives_Restore =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Roles_Archives_Restore();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Roles_Archives_Delete =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Roles_Archives_Delete();

      return result;
    } catch (e) {
      throw e;
    }
  };
export const ClientAPI_User_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_User_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_User_Save =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_User_Save();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_User_View =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_User_View();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_User_Update =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_User_Update();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_User_Delete =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_User_Delete();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_User_Change_Password =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_User_Change_Password();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_User_Archives_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_User_Archives_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_User_Archives_Restore =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_User_Archives_Restore();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_User_Archives_Delete =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_User_Archives_Delete();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Asset_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Asset_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Asset_Save =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Asset_Save();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Asset_Cancel =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Asset_Cancel();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Asset_Employee_Search =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Asset_Employee_Search();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Billing_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Billing_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Billing_Dispute_Update =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Billing_Dispute_Update();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Invoice_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Invoice_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Invoice_List_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Invoice_List_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Invoice_View =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Invoice_View();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Invoice_Summary_View =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Invoice_Summary_View();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Referrals_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Referrals_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Actual_Skills_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Actual_Skills_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Scheduler_Get =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Scheduler_Get();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Scheduler_Get_Without_Query =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Scheduler_Get_Without_Query();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Scheduler_Save =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Scheduler_Save();

      return result;
    } catch (e) {
      throw e;
    }
  };
export const ClientAPI_Outlook_Save =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Scheduler_Outlook_Calendar_Save();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Google_Save =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Scheduler_Google_Calendar_Save();

      return result;
    } catch (e) {
      throw e;
    }
  };
export const ClientAPI_Scheduler_View =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Scheduler_View();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Scheduler_Update =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Scheduler_Update();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Scheduler_Delete =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Scheduler_Delete();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Scheduler_Participants =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Scheduler_Participants();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Scheduler_Google_Calendar_Save =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Scheduler_Google_Calendar_Save();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Scheduler_Outlook_Calendar_Save =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Scheduler_Outlook_Calendar_Save();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const ClientAPI_Interview_Save =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Interview_Save();

      return result;
    } catch (e) {
      throw e;
    }
  };

export const setNotificationToken = (data) => ({
  type: ClientType.NOTIFICATION_TOKEN,
  data,
});

export const ClientAPI_Job_Requirements_config =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Job_Requirements_config();
      return result;
    } catch (error) {
      throw error;
    }
  };

export const ClientAPI_Save_Job_Requirements =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Save_Job_Requirements();

      return result;
    } catch (error) {
      throw error;
    }
  };

export const ClientAPI_Get_Filling_List =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Filling_List();

      return result;
    } catch (error) {
      throw error;
    }
  };

export const ClientAPI_Filling_Set_Status =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Filling_Set_Status();
      return result;
    } catch (error) {
      throw error;
    }
  };

export const ClientAPI_Retrieve_Schedule =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Retrieve_Schedule();

      return result;
    } catch (error) {
      throw error;
    }
  };

export const ClientAPI_Retrieve_Time_Logs =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Retrieve_Time_Logs();

      return result;
    } catch (error) {
      throw error;
    }
  };

export const ClientAPI_Save_Work_Schedule =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Save_Work_Schedule();

      return result;
    } catch (error) {
      throw error;
    }
  };

export const ClientAPI_Retrieve_Tasks =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Retrieve_Tasks();

      return result;
    } catch (error) {
      throw error;
    }
  };

export const ClientAPI_Save_Task =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Save_Task();

      return result;
    } catch (error) {
      throw error;
    }
  };

export const ClientAPI_Retrieve_Task =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Retrieve_Task();

      return result;
    } catch (error) {
      throw error;
    }
  };

export const ClientAPI_Update_Task =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Update_Task();

      return result;
    } catch (error) {
      throw error;
    }
  };

export const ClientAPI_Save_Appraisal =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Save_Appraisal();

      return result;
    } catch (error) {
      throw error;
    }
  };
export const ClientAPI_List_Appraisal =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_List_Appraisal();

      return result;
    } catch (error) {
      throw error;
    }
  };
export const ClientAPI_Request_List =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Request_List();

      return result;
    } catch (error) {
      throw error;
    }
  };
export const ClientAPI_Request_Increase =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Request_Increase();

      return result;
    } catch (error) {
      throw error;
    }
  };
export const ClientAPI_Job_Requirements_List =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Get_Job_Requirements_List();

      return result;
    } catch (error) {
      throw error;
    }
  };
export const ClientAPI_Delete_Job_Requirement =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Delete_Job_Requirement();

      return result;
    } catch (error) {
      throw error;
    }
  };
export const ClientAPI_Restore_Job_Requirement =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Restore_Job_Requirement();

      return result;
    } catch (error) {
      throw error;
    }
  };
export const ClientAPI_Delete_Permanent_Job_Requirement =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Delete_Permanent_Job_Requirement();

      return result;
    } catch (error) {
      throw error;
    }
  };
export const ClientAPI_Update_Job_Requirement =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Update_Job_Requirement();

      return result;
    } catch (error) {
      throw error;
    }
  };
export const ClientAPI_Get_Job_Requirement =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Get_Job_Requirement();

      return result;
    } catch (error) {
      throw error;
    }
  };
// set meeting by hr
export const ClientAPI_Set_Meeting_HR =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Set_Meeting_HR();

      return result;
    } catch (error) {
      throw error;
    }
  };
// get dashboard counters
export const ClientAPI_Get_Dashboard_Counters =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Get_Dashboard_Counters();

      return result;
    } catch (error) {
      throw error;
    }
  };
// get available users
export const ClientAPI_Get_Available_Users =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Get_Available_Users();

      return result;
    } catch (error) {
      throw error;
    }
  };
// get sow list
export const ClientAPI_Get_Sow_List =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Get_Sow_List();

      return result;
    } catch (error) {
      throw error;
    }
  };
// get sow file
export const ClientAPI_Get_Sow_File =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Get_Sow_File();

      return result;
    } catch (error) {
      throw error;
    }
  };
// sign sow file
export const ClientAPI_Sign_Sow_File =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Sign_Sow_File();

      return result;
    } catch (error) {
      throw error;
    }
  };

// support ticketing
export const ClientAPI_Get_Ticket_List =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Get_Ticket_List();

      return result;
    } catch (error) {
      throw error;
    }
  };

export const ClientAPI_Request_Ticket =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Request_Ticket();

      return result;
    } catch (error) {
      throw error;
    }
  };

export const ClientAPI_Update_Ticket =
  (query, params, header, controller) => async (dispatch) => {
    try {
      const result = await Client(
        query,
        params,
        header,
        controller
      ).API_Update_Ticket();

      return result;
    } catch (error) {
      throw error;
    }
  };