import React from "react";
import { checkAuth } from "./redux/actions/authAction";
import ReactDOM from "react-dom";
import Store from "./store";
import Routes from "./routes";
import './index.scss';
import 'react-phone-input-2/lib/material.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-datepicker/dist/react-datepicker.css";
import 'react-medium-image-zoom/dist/styles.css'
import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const StoreInstance = Store();

StoreInstance.dispatch(checkAuth());
serviceWorkerRegistration.register();
ReactDOM.render(<Routes store={StoreInstance}/>, document.getElementById("root"));