import React, { useState } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";

import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import Collapse from "@mui/material/Collapse";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import lodashFlattenDeep from "lodash/flattenDeep";
import lodashIsEmpty from "lodash/isEmpty";
import Flag from "react-flagkit";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as clientAction from "../../../redux/actions/clientAction";
import DashboardIcon from "../../../assets/menu/dashboard-icon.png";
import ReferralIcon from "../../../assets/menu/referral-icon.png";
import AccountIcon from "../../../assets/menu/account-icon.png";
import ManagementIcon from "../../../assets/menu/management-icon.png";
import BillingIcon from "../../../assets/menu/billing-icon.png";
import PaymentIcon from "../../../assets/menu/payment-icon.png";
import FAQIcon from "../../../assets/menu/faq-icon.png";
import LogoutIcon from "../../../assets/menu/logout-icon.png";
import MenuBarIcon from "../../../assets/header/menu-bar-icon.png";
import ExpandLessIcon from "../../../assets/menu/expand-less-icon.png";
import ExpandMoreIcon from "../../../assets/menu/expand-more-icon.png";
// import RequestAssetIcon from '../../../assets/header/request-asset-icon.png';
import ReportsIcon from "../../../assets/menu/reports-icon.png";
import AssetRequestIcon from "../../../assets/menu/asset-request-icon.png";
import SettingsOutlinedIcon from "../../../assets/menu/system-cofig-icon.png";
// import BrandLogoWhite from '../../../assets/brandLogoWhite.svg';
// import BrandLogoWhiteWithoutText from '../../../assets/brandLogoWhiteWithoutText.png';
import { getToken, getUser, removeUserSession } from "../../../utils/auth";
import { showError } from "../../../utils/api_resp_display";
import "./_index.scss";
import Notifications from "./Notifications";
import ActiveUsers from "./ActiveUsers";

import { useGetNotifications } from "../../../query/notifications/NotificationQueries";
import NotificationBanner from "./NotificationBanner";
import { DashboardAds } from "../dashboard/DashboardAds";

const drawerWidth = 270;

const openedMixin = (theme) => ({
  width: drawerWidth,
  border: 0,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: "hidden",
  zIndex: "auto",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(10)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  height: 80,
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Layout = ({ children, clientAction, client }) => {
  const [logoutLoading, setLogoutLoading] = useState(false);
  const { menu, next_link } = getUser() || [];
  const { first_name, picture, location } = getUser() || "";
  const controller = new AbortController();

  const openSubMenu =
    menu && menu.length > 0
      ? menu.map((value) =>
        value.menu.map((value2) =>
          value2.length > 0
            ? value2.map(
              (value3) =>
                value3.sub_menu &&
                value3.sub_menu.length > 0 &&
                value3.label
            )
            : value2.sub_menu && value2.sub_menu.length > 0 && value2.label
        )
      )
      : [];

  const openSubLevel =
    menu && menu.length > 0
      ? menu.map((value) =>
        value.menu.map((value2) =>
          value2.sub_menu.length > 0
            ? value2.sub_menu.map((value3) => {
              return value3.sub_level.length > 0 ? value3.label : undefined;
            })
            : value2.sub_menu && value2.sub_menu.length > 0 && value2.label
        )
      )
      : [];

  const [open, setOpen] = useState(true);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(
    lodashFlattenDeep(openSubMenu).filter(Boolean) || []
  );
  const [subLevelOpen, setSubLevelOpen] = useState(
    lodashFlattenDeep(openSubLevel).filter(
      (item) => typeof item === "string"
    ) || []
  );

  const [anchorElHeader, setAnchorElHeader] = useState(null);
  const contextMenuHeader = Boolean(anchorElHeader);

  const [anchorElFlagHeader, setAnchorElFlagHeader] = useState(null);
  const contextFlagHeader = Boolean(anchorElFlagHeader);
  const [currentFlag, setCurrentFlag] = useState(location || "PH");
  const [notifBanner, setNotifBanner] = useState(true);

  const splitPathname = window.location.pathname.split("/");

  const token = getToken();

  const referralCodeSession = JSON.parse(
    sessionStorage.getItem("referralCode")
  );

  const { data: notificationList } = useGetNotifications(token);

  const onLogout = () => {
    setLogoutLoading(true);
    clientAction
      .ClientAPI_Logout(null, null, { "x-access-token": token })
      .then((res) => {
        window.location.href = "/login";
        removeUserSession();
        if (referralCodeSession) {
          sessionStorage.removeItem("referralCode");
          sessionStorage.removeItem("referralResumeId");
          sessionStorage.removeItem("referralPositionId");
        }
      })
      .catch((err) => {
        setLogoutLoading(false);
        showError(err);
      });
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerOpenMobile = () => {
    setMobileDrawerOpen(true);
  };

  const handleDrawerCloseMobile = () => {
    setMobileDrawerOpen(false);
  };

  const onContextMenuHeaderShow = (event) => {
    setAnchorElHeader(event.currentTarget);
  };

  const onContextMenuHeaderHide = () => {
    setAnchorElHeader(null);
  };

  const onContextFlagHeaderShow = (event) => {
    setAnchorElFlagHeader(event.currentTarget);
  };

  const onContextFlagHeaderHide = () => {
    setAnchorElFlagHeader(null);
  };

  const onSubMenuClick = (subMenuLabel) => {
    if (subMenuOpen.includes(subMenuLabel)) {
      setSubMenuOpen(subMenuOpen.filter((value) => value !== subMenuLabel));
    } else {
      setSubMenuOpen([...subMenuOpen, subMenuLabel]);
    }
  };

  const onSubLevelClick = (subLevelLabel) => {
    if (subLevelOpen.includes(subLevelLabel)) {
      setSubLevelOpen(subLevelOpen.filter((value) => value !== subLevelLabel));
    } else {
      setSubLevelOpen([...subLevelOpen, subLevelLabel]);
    }
  };

  const getPathnameMenu = () => {
    const currentLocation = window.location.pathname;

    if (currentLocation.includes("/client/dashboard")) {
      return "Dashboard";
    } else if (currentLocation.includes("/client/referrals")) {
      return "Referrals";
    } else if (currentLocation.includes("/client/account/profile")) {
      return "Profile";
    } else if (currentLocation.includes("/client/account/user")) {
      return "User";
    } else if (currentLocation.includes("/client/account/roles")) {
      return "Roles";
    } else if (
      currentLocation.includes(
        "/client/management/employee-management/request-list"
      )
    ) {
      return "Request List";
    } else if (
      currentLocation.includes(
        "/client/management/candidate-management/job-requirement"
      )
    ) {
      return "Job requirement";
    } else if (
      currentLocation.includes(
        "/client/management/candidate-management/candidate-search"
      )
    ) {
      return "Candidate search";
    } else if (
      currentLocation.includes(
        "/client/management/candidate-management/candidate-list"
      )
    ) {
      return "Candidate list";
    } else if (
      currentLocation.includes(
        "/client/management/employee-management/employee"
      )
    ) {
      return "Employee";
    } else if (
      currentLocation.includes(
        "/client/management/employee-management/department"
      )
    ) {
      return "Employee Department";
    } else if (currentLocation.includes("/client/payment-method")) {
      return "Payment method";
    } else if (currentLocation.includes("/client/billingAndInvoices/billing")) {
      return "Billing";
    } else if (
      currentLocation.includes("/client/billingAndInvoices/invoices-history")
    ) {
      return "Invoices History";
    } else if (currentLocation.includes("/client/reports")) {
      return "Reports";
    } else if (currentLocation.includes("/client/faq")) {
      return "FAQ";
    }
  };

  const getPathnameSubLevelMenu = () => {
    const currentLocation = window.location.pathname;

    if (currentLocation.includes("/candidate-management")) {
      return "Candidate Management";
    }

    if (currentLocation.includes("/employee-management")) {
      return "Employee Management";
    }
  };

  const getPathnameSubMenu = () => {
    if (splitPathname && splitPathname[2]) {
      switch (splitPathname[2]) {
        case "account":
          return "Account";
        case "management":
          return "Management";
        case "billingAndInvoices":
          return "Billing & Invoices";
        default:
          return;
      }
    }
  };

  const setPathnameMenu = (label) => {
    switch (label) {
      case "Dashboard":
        return "/client/dashboard";
      // case "Request List":
      //   return "/client/request-list/bonus";
      case "Referrals":
        return "/client/referrals";
      case "Payment method":
        return "/client/payment-method";
      case "Reports":
        return "/client/reports";
      case "System Configuration":
        return "/client/system-configuration/department";
      case "FAQ":
        return "/client/faq";
      default:
        return "/";
    }
  };

  const setPathnameSubMenu = (label) => {
    switch (label) {
      case "Profile":
        return "/client/account/profile/about";
      case "User":
        return "/client/account/user";
      case "Roles":
        return "/client/account/roles";
      case "Job requirement":
        return "/client/management/candidate-management/job-requirement";
      case "Candidate search":
        return "/client/management/candidate-management/candidate-search";
      case "Candidate list":
        return "/client/management/candidate-management/candidate-list/my-interview";
      case "Request List":
        return "/client/management/employee-management/request-list/bonus";
      case "Employee":
        return "/client/management/employee-management/employee/on-boarding";
      case "Employee Department":
        return "/client/management/employee-management/department";
      case "Billing":
        return "/client/billingAndInvoices/billing";
      case "Invoices History":
        return "/client/billingAndInvoices/invoices-history";
      default:
        return "/";
    }
  };

  const setMenuIcon = (label, filter) => {
    switch (label) {
      case "Dashboard":
        return (
          <img
            loading="lazy"
            src={DashboardIcon}
            alt={`${label}-icon`}
            style={{ width: "100%", filter }}
          />
        );
      case "Referrals":
        return (
          <img
            loading="lazy"
            src={ReferralIcon}
            alt={`${label}-icon`}
            style={{ width: "100%", filter }}
          />
        );
      case "Account":
        return (
          <img
            loading="lazy"
            src={AccountIcon}
            alt={`${label}-icon`}
            style={{ width: "100%", filter }}
          />
        );
      case "Request List":
        return (
          <img
            loading="lazy"
            src={AssetRequestIcon}
            alt={`${label}-icon`}
            style={{ width: "100%", filter }}
          />
        );
      case "Management":
        return (
          <img
            loading="lazy"
            src={ManagementIcon}
            alt={`${label}-icon`}
            style={{ width: "100%", filter }}
          />
        );
      case "Billing & Invoices":
        return (
          <img
            loading="lazy"
            src={BillingIcon}
            alt={`${label}-icon`}
            style={{ width: "100%", filter }}
          />
        );
      case "Payment method":
        return (
          <img
            loading="lazy"
            src={PaymentIcon}
            alt={`${label}-icon`}
            style={{ width: "100%", filter }}
          />
        );
      case "Reports":
        return (
          <img
            loading="lazy"
            src={ReportsIcon}
            alt={`${label}-icon`}
            style={{ width: "100%", filter }}
          />
        );
      case "System Configuration":
        return (
          <img
            loading="lazy"
            src={SettingsOutlinedIcon}
            alt={`${label}-icon`}
            style={{ width: "100%", filter }}
          />
        );
      case "FAQ":
        return (
          <img
            loading="lazy"
            src={FAQIcon}
            alt={`${label}-icon`}
            style={{ width: "100%", filter }}
          />
        );
      default:
        return;
    }
  };

  const setMenuToggledIcon = (label, filter) => {
    switch (label) {
      case "Dashboard":
        return (
          <img
            loading="lazy"
            src={DashboardIcon}
            alt={`${label}-icon`}
            style={{ width: 25, margin: "0 auto", filter }}
          />
        );
      case "Referrals":
        return (
          <img
            loading="lazy"
            src={ReferralIcon}
            alt={`${label}-icon`}
            style={{ width: 25, margin: "0 auto", filter }}
          />
        );
      case "System Configuration":
        return (
          <img
            loading="lazy"
            src={SettingsOutlinedIcon}
            alt={`${label}-icon`}
            style={{ width: "100%", filter }}
          />
        );
      case "Account":
        return (
          <img
            loading="lazy"
            src={AccountIcon}
            alt={`${label}-icon`}
            style={{ width: 25, margin: "0 auto", filter }}
          />
        );
      case "Request":
        return (
          <img
            loading="lazy"
            src={AssetRequestIcon}
            alt={`${label}-icon`}
            style={{ width: 25, margin: "0 auto", filter }}
          />
        );
      case "Request List":
        return (
          <img
            loading="lazy"
            src={AssetRequestIcon}
            alt={`${label}-icon`}
            style={{ width: 25, margin: "0 auto", filter }}
          />
        );
      case "Management":
        return (
          <img
            loading="lazy"
            src={ManagementIcon}
            alt={`${label}-icon`}
            style={{ width: 25, margin: "0 auto", filter }}
          />
        );
      case "Billing & Invoices":
        return (
          <img
            loading="lazy"
            src={BillingIcon}
            alt={`${label}-icon`}
            style={{ width: 25, margin: "0 auto", filter }}
          />
        );
      case "Payment method":
        return (
          <img
            loading="lazy"
            src={PaymentIcon}
            alt={`${label}-icon`}
            style={{ width: 25, margin: "0 auto", filter }}
          />
        );
      case "Reports":
        return (
          <img
            loading="lazy"
            src={ReportsIcon}
            alt={`${label}-icon`}
            style={{ width: 25, margin: "0 auto", filter }}
          />
        );
      case "FAQ":
        return (
          <img
            loading="lazy"
            src={FAQIcon}
            alt={`${label}-icon`}
            style={{ width: 25, margin: "0 auto", filter }}
          />
        );
      default:
        return;
    }
  };

  const {
    companyProfile: { icon, logo_client_white },
  } = client;

  const faqScreen = window.location.pathname.includes("/client/faq");

  return (
    <>
      {notifBanner &&
        notificationList &&
        notificationList.notifications.length > 0 &&
        next_link?.includes("ApplicantSearch") && (
          <div
            style={{
              position: "fixed",
              top: 0,
              width: "100%",
              zIndex: 1000,
            }}
          >
            <NotificationBanner setNotifBanner={setNotifBanner} />
          </div>
        )}
      <div className="logged-in-layout-container-div">
        <Box
          sx={{ display: "flex", minHeight: "100vh", background: "#E8E8E8" }}
        >
          <CssBaseline />
          <AppBar
            position="fixed"
            open={open}
            sx={{
              top:
                notifBanner &&
                  notificationList &&
                  notificationList.notifications.length > 0 &&
                  next_link.includes("ApplicantSearch") &&
                  !mobileDrawerOpen
                  ? "48px"
                  : 0,
            }}
          >
            <Toolbar className="toolbar-layout">
              {!open && (
                <div style={{ display: "flex" }}>
                  <img
                    loading="lazy"
                    src={icon}
                    alt="logo-white-without-text"
                    style={{ width: "68px" }}
                  />
                </div>
              )}
              {open && (
                <img
                  loading="lazy"
                  src={MenuBarIcon}
                  alt="menu-bar-icon"
                  onClick={handleDrawerClose}
                  style={{ marginLeft: 20, cursor: "pointer" }}
                  className="sidebar-menu-drawer"
                />
              )}
              {!mobileDrawerOpen && (
                <img
                  loading="lazy"
                  src={MenuBarIcon}
                  alt="menu-bar-icon"
                  onClick={handleDrawerOpenMobile}
                  style={{ marginLeft: 20, cursor: "pointer" }}
                  className="sidebar-menu-drawer-mobile"
                />
              )}
              {!open && (
                <ArrowForwardIcon
                  style={{ marginLeft: 15, cursor: "pointer" }}
                  onClick={handleDrawerOpen}
                  className="sidebar-menu-drawer"
                />
              )}
              {mobileDrawerOpen && (
                <ArrowBackIcon
                  style={{ marginLeft: 15, cursor: "pointer" }}
                  onClick={handleDrawerCloseMobile}
                  className="sidebar-menu-drawer-mobile"
                />
              )}
              <ActiveUsers clientAction={clientAction} />
              <div
                style={{
                  position: "absolute",
                  right: "30px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* <Tooltip title="Request Asset" placement="bottom">
                <div className="header-top-icons" style={{ marginRight: 15 }}>
                  <Link to="/client/asset-request"><img src={RequestAssetIcon} alt="request-asset" style={{ width: "100%", cursor: "pointer" }} /></Link>
                </div>
              </Tooltip> */}
                {/* <Notifications clientAction={clientAction} /> */}
                {!lodashIsEmpty(picture) ? (
                  <Avatar
                    sx={{ width: 36, height: 36, marginLeft: 3 }}
                    variant="square"
                    src={picture}
                  />
                ) : (
                  <Avatar
                    sx={{
                      width: 36,
                      height: 36,
                      marginLeft: 3.0,
                      borderRadius: "5px !important",
                    }}
                    variant="square"
                  >
                    {first_name && first_name.toUpperCase().charAt(0)}
                  </Avatar>
                )}
                <div className="header-top-name">
                  Hey,{" "}
                  <span style={{ color: "white", fontWeight: "500" }}>
                    {first_name}
                  </span>
                  <br />
                  Role: Client
                </div>
                {/* <Tooltip title="Menu" placement="bottom">
                  <IconButton
                    onClick={onContextMenuHeaderShow}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    style={{ marginLeft: 15 }}
                  >
                    <MoreHorizIcon className="header-top-icons" />
                  </IconButton>
                </Tooltip> */}
                {/* {country ? */}
                <Tooltip
                  title={
                    currentFlag === "PH"
                      ? "Philippines"
                      : currentFlag === "US"
                        ? "United States"
                        : currentFlag === "MX"
                          ? "Mexico"
                          : "Poland"
                  }
                  placement="bottom"
                >
                  <div className="header-top-icons" style={{ marginLeft: 24 }}>
                    <Flag
                      country={currentFlag}
                      style={{ width: "100%", cursor: "pointer" }}
                      onClick={onContextFlagHeaderShow}
                    />
                  </div>
                </Tooltip>
                {/* :
                <FlagCircleIcon className="header-top-icons" style={{ marginLeft: 10, cursor: "pointer" }} onClick={onContextFlagHeaderShow} />
              } */}
                <Menu
                  anchorEl={anchorElFlagHeader}
                  id="flag-menu"
                  open={contextFlagHeader}
                  onClose={onContextFlagHeaderHide}
                  onClick={onContextFlagHeaderHide}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      border: "none !important",
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <div style={{ maxHeight: "187px", overflowY: "scroll" }}>
                    <MenuItem onClick={() => setCurrentFlag("PH")}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Flag country={"PH"} size={20} />
                        &emsp;
                        <span>Philippines</span>
                      </div>
                    </MenuItem>
                    <MenuItem onClick={() => setCurrentFlag("US")}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Flag country={"US"} size={20} />
                        &emsp;
                        <span>United States</span>
                      </div>
                    </MenuItem>
                    <MenuItem onClick={() => setCurrentFlag("CA")}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Flag country={"CA"} size={20} />
                        &emsp;
                        <span>Canada</span>
                      </div>
                    </MenuItem>
                    <MenuItem onClick={() => setCurrentFlag("MX")}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Flag country={"MX"} size={20} />
                        &emsp;
                        <span>Mexico</span>
                      </div>
                    </MenuItem>
                    <MenuItem onClick={() => setCurrentFlag("PL")}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Flag country={"PL"} size={20} />
                        &emsp;
                        <span>Poland</span>
                      </div>
                    </MenuItem>
                    <MenuItem onClick={() => setCurrentFlag("VN")}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Flag country={"VN"} size={20} />
                        &emsp;
                        <span>Vietnam</span>
                      </div>
                    </MenuItem>
                    <MenuItem onClick={() => setCurrentFlag("HK")}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Flag country={"HK"} size={20} />
                        &emsp;
                        <span>Hong Kong</span>
                      </div>
                    </MenuItem>
                    <MenuItem onClick={() => setCurrentFlag("GB")}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Flag country={"GB"} size={20} />
                        &emsp;
                        <span>United Kingdom</span>
                      </div>
                    </MenuItem>
                    <MenuItem onClick={() => setCurrentFlag("CA")}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Flag country={"CA"} size={20} />
                        &emsp;
                        <span>Canada</span>
                      </div>
                    </MenuItem>
                    <MenuItem onClick={() => setCurrentFlag("CO")}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Flag country={"CO"} size={20} />
                        &emsp;
                        <span>Columbia</span>
                      </div>
                    </MenuItem>
                    <MenuItem onClick={() => setCurrentFlag("IN")}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Flag country={"IN"} size={20} />
                        &emsp;
                        <span>India</span>
                      </div>
                    </MenuItem>
                    <MenuItem onClick={() => setCurrentFlag("CN")}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Flag country={"CN"} size={20} />
                        &emsp;
                        <span>China</span>
                      </div>
                    </MenuItem>
                    <MenuItem onClick={() => setCurrentFlag("PR")}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Flag country={"PR"} size={20} />
                        &emsp;
                        <span>Puerto Rico</span>
                      </div>
                    </MenuItem>
                    <MenuItem onClick={() => setCurrentFlag("EG")}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Flag country={"EG"} size={20} />
                        &emsp;
                        <span>Egypt</span>
                      </div>
                    </MenuItem>
                  </div>
                </Menu>
                <Menu
                  anchorEl={anchorElHeader}
                  id="account-menu"
                  open={contextMenuHeader}
                  onClose={onContextMenuHeaderHide}
                  onClick={onContextMenuHeaderHide}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      border: "none !important",
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem onClick={onLogout}>
                    Log out{" "}
                    {logoutLoading && (
                      <CircularProgress
                        color="secondary"
                        size={12}
                        style={{ marginLeft: 10 }}
                      />
                    )}
                  </MenuItem>
                </Menu>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            open={open}
            className="sidebar-menu-drawer"
            sx={{
              top:
                notifBanner &&
                  notificationList &&
                  notificationList.notifications.length > 0 &&
                  next_link.includes("ApplicantSearch")
                  ? "48px"
                  : 0,
              position:
                notifBanner &&
                  notificationList &&
                  notificationList.notifications.length > 0 &&
                  next_link.includes("ApplicantSearch")
                  ? "relative"
                  : "static",
              "&.MuiDrawer-docked .MuiDrawer-paper": {
                top:
                  notifBanner &&
                    notificationList &&
                    notificationList.notifications.length > 0 &&
                    next_link.includes("ApplicantSearch")
                    ? "48px"
                    : 0,
                paddingBottom:
                  notifBanner &&
                    notificationList &&
                    notificationList.notifications.length > 0 &&
                    next_link.includes("ApplicantSearch") &&
                    !mobileDrawerOpen
                    ? "48px"
                    : 0,
              },
            }}
          >
            <DrawerHeader className="sidebar-drawer-header">
              <img
                loading="lazy"
                src={logo_client_white}
                alt="logo-white"
                style={{ width: "100%" }}
              />
            </DrawerHeader>
            <Divider />
            {open ? (
              <div
                style={{
                  overflowY: "auto",
                  paddingBottom:
                    window.location.href.indexOf("client/dashboard") > -1 &&
                    "180px",
                }}
              >
                {menu &&
                  menu.length > 0 &&
                  menu.map((value, key) => {
                    return (
                      <React.Fragment key={key}>
                        <List
                          style={{
                            padding:
                              key === 0 ? "0 10px 10px 10px" : "20px 10px",
                            marginTop: key === 0 && 20,
                          }}
                          subheader={
                            <ListSubheader
                              disableSticky
                              component="div"
                              id={`nested-list-subheader${key}`}
                              className="sidebar-list-sub-header"
                            >
                              {value.label.toUpperCase()}
                            </ListSubheader>
                          }
                        >
                          {
                            // * main menu
                            value.menu &&
                            value.menu.length > 0 &&
                            value.menu.map((value2, key2) => {
                              return (
                                <React.Fragment key={key2}>
                                  {value2 && value2.length > 0 ? (
                                    <React.Fragment>
                                      {value2.map(
                                        (value2Value, value2Key) => {
                                          return (
                                            <React.Fragment key={value2Key}>
                                              {
                                                // * sub menu
                                                value2Value.sub_menu &&
                                                  value2Value.sub_menu.length >
                                                  0 ? (
                                                  <ListItem
                                                    button
                                                    className={
                                                      getPathnameSubMenu() ===
                                                        value2Value.label
                                                        ? "sidebar-is-selected-color"
                                                        : "sidebar-is-not-selected-color"
                                                    }
                                                    onClick={() =>
                                                      value2Value.sub_menu
                                                        .length > 0 &&
                                                      onSubMenuClick(
                                                        value2Value.label
                                                      )
                                                    }
                                                  >
                                                    <ListItemIcon
                                                      style={{ minWidth: 25 }}
                                                    >
                                                      <div
                                                        className={
                                                          getPathnameSubMenu() ===
                                                            value2Value.label
                                                            ? "sidebar-is-selected-bg-color"
                                                            : "sidebar-is-not-selected-bg-color"
                                                        }
                                                      >
                                                        {setMenuIcon(
                                                          value2Value.label,
                                                          getPathnameSubMenu() ===
                                                          value2Value.label &&
                                                          "brightness(0) saturate(100%) invert(31%) sepia(49%) saturate(597%) hue-rotate(215deg) brightness(88%) contrast(102%)"
                                                        )}
                                                      </div>
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                      <span
                                                        style={{
                                                          fontSize: ".8rem",
                                                        }}
                                                      >
                                                        {value2Value.label}
                                                      </span>
                                                    </ListItemText>
                                                    {value2Value.sub_menu
                                                      .length > 0 &&
                                                      (subMenuOpen.includes(
                                                        value2Value.label
                                                      ) ? (
                                                        <img
                                                          loading="lazy"
                                                          src={ExpandLessIcon}
                                                          alt="expand-less-icon"
                                                        />
                                                      ) : (
                                                        <img
                                                          loading="lazy"
                                                          src={ExpandMoreIcon}
                                                          alt="expand-more-icon"
                                                        />
                                                      ))}
                                                  </ListItem>
                                                ) : (
                                                  <Link
                                                    to={setPathnameMenu(
                                                      value2Value.label
                                                    )}
                                                    style={{
                                                      textDecoration: "none",
                                                    }}
                                                  >
                                                    <ListItem
                                                      button
                                                      className={
                                                        getPathnameMenu(
                                                          value2Value.label
                                                        ) ===
                                                          value2Value.label
                                                          ? "sidebar-is-selected-color"
                                                          : "sidebar-is-not-selected-color"
                                                      }
                                                    >
                                                      <ListItemIcon
                                                        style={{
                                                          minWidth: 25,
                                                        }}
                                                      >
                                                        <div
                                                          className={
                                                            getPathnameMenu(
                                                              value2Value.label
                                                            ) ===
                                                              value2Value.label
                                                              ? "sidebar-is-selected-bg-color"
                                                              : "sidebar-is-not-selected-bg-color"
                                                          }
                                                        >
                                                          {setMenuIcon(
                                                            value2Value.label,
                                                            getPathnameMenu(
                                                              value2Value.label
                                                            ) ===
                                                            value2Value.label &&
                                                            "brightness(0) saturate(100%) invert(31%) sepia(49%) saturate(597%) hue-rotate(215deg) brightness(88%) contrast(102%)"
                                                          )}
                                                        </div>
                                                      </ListItemIcon>
                                                      <ListItemText>
                                                        <span
                                                          style={{
                                                            fontSize: ".8rem",
                                                          }}
                                                        >
                                                          {value2Value.label}
                                                        </span>
                                                      </ListItemText>
                                                    </ListItem>
                                                  </Link>
                                                )
                                              }
                                              {value2Value.sub_menu &&
                                                value2Value.sub_menu.length >
                                                0 &&
                                                value2Value.sub_menu.map(
                                                  (value3, key3) => {
                                                    return (
                                                      <Collapse
                                                        in={subMenuOpen.includes(
                                                          value2Value.label
                                                        )}
                                                        timeout="auto"
                                                        unmountOnExit
                                                        key={key3}
                                                      >
                                                        <List
                                                          component="div"
                                                          disablePadding
                                                        >
                                                          <Link
                                                            to={setPathnameSubMenu(
                                                              value3.label
                                                            )}
                                                            style={{
                                                              textDecoration:
                                                                "none",
                                                            }}
                                                          >
                                                            <ListItem
                                                              button
                                                              className={
                                                                getPathnameMenu(
                                                                  value3.label
                                                                ) ===
                                                                  value3.label
                                                                  ? "sidebar-is-selected-color"
                                                                  : "sidebar-is-not-selected-color"
                                                              }
                                                              style={{
                                                                paddingTop: 0,
                                                                paddingBottom: 0,
                                                                height: 25,
                                                              }}
                                                            >
                                                              <ListItemIcon
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  justifyContent:
                                                                    "right",
                                                                  paddingRight: 10,
                                                                  minWidth: 25,
                                                                }}
                                                              >
                                                                <div
                                                                  className={
                                                                    getPathnameMenu(
                                                                      value3.label
                                                                    ) ===
                                                                      value3.label
                                                                      ? "sidebar-list-sub-header-dot-selected"
                                                                      : "sidebar-list-sub-header-dot"
                                                                  }
                                                                />
                                                              </ListItemIcon>
                                                              <ListItemText>
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      ".8rem",
                                                                    fontWeight:
                                                                      getPathnameMenu(
                                                                        value3.label
                                                                      ) ===
                                                                      value3.label &&
                                                                      600,
                                                                    // textDecoration:
                                                                    //   getPathnameMenu(
                                                                    //     value3.label
                                                                    //   ) ===
                                                                    //     value3.label &&
                                                                    //   "underline",
                                                                  }}
                                                                >
                                                                  {
                                                                    value3.label
                                                                  }
                                                                </span>
                                                              </ListItemText>
                                                            </ListItem>
                                                          </Link>
                                                        </List>
                                                      </Collapse>
                                                    );
                                                  }
                                                )}
                                            </React.Fragment>
                                          );
                                        }
                                      )}
                                    </React.Fragment>
                                  ) : (
                                    // * this is the actual menu
                                    <React.Fragment>
                                      {value2.sub_menu &&
                                        value2.sub_menu.length > 0 ? (
                                        <ListItem
                                          button
                                          className={
                                            getPathnameSubMenu() ===
                                              value2.label
                                              ? "sidebar-is-selected-color"
                                              : "sidebar-is-not-selected-color"
                                          }
                                          onClick={() =>
                                            value2.sub_menu.length > 0 &&
                                            onSubMenuClick(value2.label)
                                          }
                                        >
                                          <ListItemIcon
                                            style={{ minWidth: 38 }}
                                          >
                                            <div
                                              className={
                                                getPathnameSubMenu() ===
                                                  value2.label
                                                  ? "sidebar-is-selected-bg-color"
                                                  : "sidebar-is-not-selected-bg-color"
                                              }
                                            >
                                              {setMenuIcon(
                                                value2.label,
                                                getPathnameSubMenu() ===
                                                value2.label &&
                                                "brightness(0) saturate(100%) invert(31%) sepia(49%) saturate(597%) hue-rotate(215deg) brightness(88%) contrast(102%)"
                                              )}
                                            </div>
                                          </ListItemIcon>
                                          <ListItemText>
                                            <span
                                              style={{ fontSize: ".8rem" }}
                                            >
                                              {value2.label}
                                            </span>
                                          </ListItemText>
                                          {value2.sub_menu.length > 0 &&
                                            (subMenuOpen.includes(
                                              value2.label
                                            ) ? (
                                              <img
                                                loading="lazy"
                                                src={ExpandLessIcon}
                                                alt="expand-less-icon"
                                              />
                                            ) : (
                                              <img
                                                loading="lazy"
                                                src={ExpandMoreIcon}
                                                alt="expand-more-icon"
                                              />
                                            ))}
                                        </ListItem>
                                      ) : (
                                        <Link
                                          to={setPathnameMenu(value2.label)}
                                          style={{ textDecoration: "none" }}
                                        >
                                          <ListItem
                                            button
                                            className={
                                              getPathnameMenu(
                                                value2.label
                                              ) === value2.label
                                                ? "sidebar-is-selected-color"
                                                : "sidebar-is-not-selected-color"
                                            }
                                          >
                                            <ListItemIcon
                                              style={{ minWidth: 38 }}
                                            >
                                              <div
                                                className={
                                                  getPathnameMenu(
                                                    value2.label
                                                  ) === value2.label
                                                    ? "sidebar-is-selected-bg-color"
                                                    : "sidebar-is-not-selected-bg-color"
                                                }
                                              >
                                                {setMenuIcon(
                                                  value2.label,
                                                  getPathnameMenu(
                                                    value2.label
                                                  ) === value2.label &&
                                                  "brightness(0) saturate(100%) invert(31%) sepia(49%) saturate(597%) hue-rotate(215deg) brightness(88%) contrast(102%)"
                                                )}
                                              </div>
                                            </ListItemIcon>
                                            <ListItemText>
                                              <span
                                                style={{ fontSize: ".8rem" }}
                                              >
                                                {value2.label}
                                              </span>
                                            </ListItemText>
                                          </ListItem>
                                        </Link>
                                      )}
                                      {value2.sub_menu &&
                                        value2.sub_menu.length > 0 &&
                                        value2.sub_menu.map(
                                          (value3, key3) => {
                                            return (
                                              <Collapse
                                                in={subMenuOpen.includes(
                                                  value2.label
                                                )}
                                                timeout="auto"
                                                unmountOnExit
                                                key={key3}
                                              >
                                                <List
                                                  component="div"
                                                  disablePadding
                                                >
                                                  {value3.sub_level &&
                                                    value3.sub_level.length >
                                                    0 ? (
                                                    <ListItem
                                                      onClick={() =>
                                                        value3.sub_level
                                                          .length > 0 &&
                                                        onSubLevelClick(
                                                          value3.label
                                                        )
                                                      }
                                                      button
                                                      className={
                                                        getPathnameSubLevelMenu(
                                                          value3.label
                                                        ) === value3.label
                                                          ? "sidebar-is-selected-color"
                                                          : "sidebar-is-not-selected-color"
                                                      }
                                                      style={{
                                                        paddingTop: 0,
                                                        paddingBottom: 0,
                                                        height: 25,
                                                      }}
                                                    >
                                                      <ListItemIcon
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "right",
                                                          paddingRight: 10,
                                                          minWidth: 25,
                                                        }}
                                                      >
                                                        <div
                                                          className={
                                                            getPathnameMenu(
                                                              value3.label
                                                            ) === value3.label
                                                              ? "sidebar-list-sub-header-dot-selected"
                                                              : "sidebar-list-sub-header-dot"
                                                          }
                                                        />
                                                      </ListItemIcon>
                                                      <ListItemText>
                                                        <span
                                                          style={{
                                                            fontSize: ".8rem",
                                                            fontWeight:
                                                              getPathnameMenu(
                                                                value3.label
                                                              ) ===
                                                              value3.label &&
                                                              600,
                                                            // textDecoration:
                                                            //   getPathnameMenu(
                                                            //     value3.label
                                                            //   ) ===
                                                            //     value3.label &&
                                                            //   "underline",
                                                          }}
                                                        >
                                                          {value3.label}
                                                        </span>
                                                      </ListItemText>
                                                      {value3.sub_level
                                                        .length > 0 &&
                                                        (subLevelOpen.includes(
                                                          value3.label
                                                        ) ? (
                                                          <img
                                                            loading="lazy"
                                                            src={
                                                              ExpandLessIcon
                                                            }
                                                            alt="expand-less-icon"
                                                          />
                                                        ) : (
                                                          <img
                                                            loading="lazy"
                                                            src={
                                                              ExpandMoreIcon
                                                            }
                                                            alt="expand-more-icon"
                                                          />
                                                        ))}
                                                    </ListItem>
                                                  ) : (
                                                    <Link
                                                      to={setPathnameSubMenu(
                                                        value3.label
                                                      )}
                                                      style={{
                                                        textDecoration:
                                                          "none",
                                                      }}
                                                    >
                                                      <ListItem
                                                        button
                                                        className={
                                                          getPathnameMenu(
                                                            value3.label
                                                          ) === value3.label
                                                            ? "sidebar-is-selected-color"
                                                            : "sidebar-is-not-selected-color"
                                                        }
                                                        style={{
                                                          paddingTop: 0,
                                                          paddingBottom: 0,
                                                          height: 25,
                                                        }}
                                                      >
                                                        <ListItemIcon
                                                          style={{
                                                            display: "flex",
                                                            justifyContent:
                                                              "right",
                                                            paddingRight: 10,
                                                            minWidth: 25,
                                                          }}
                                                        >
                                                          <div
                                                            className={
                                                              getPathnameMenu(
                                                                value3.label
                                                              ) ===
                                                                value3.label
                                                                ? "sidebar-list-sub-header-dot-selected"
                                                                : "sidebar-list-sub-header-dot"
                                                            }
                                                          />
                                                        </ListItemIcon>
                                                        <ListItemText>
                                                          <span
                                                            style={{
                                                              fontSize:
                                                                ".8rem",
                                                              fontWeight:
                                                                getPathnameMenu(
                                                                  value3.label
                                                                ) ===
                                                                value3.label &&
                                                                600,
                                                              // textDecoration:
                                                              //   getPathnameMenu(
                                                              //     value3.label
                                                              //   ) ===
                                                              //     value3.label &&
                                                              //   "underline",
                                                            }}
                                                          >
                                                            {value3.label}
                                                          </span>
                                                        </ListItemText>
                                                      </ListItem>
                                                    </Link>
                                                  )}
                                                </List>
                                                {value3.sub_level &&
                                                  value3.sub_level.length >
                                                  0 && (
                                                    <Collapse
                                                      in={subLevelOpen.includes(
                                                        value3.label
                                                      )}
                                                      timeout="auto"
                                                      unmountOnExit
                                                    >
                                                      <List
                                                        component="div"
                                                        disablePadding
                                                        sx={{
                                                          marginBottom:
                                                            "10px",
                                                        }}
                                                      >
                                                        {value3.sub_level.map(
                                                          (value4, key4) => (
                                                            <Link
                                                              key={key4}
                                                              to={setPathnameSubMenu(
                                                                value4.label
                                                              )}
                                                              style={{
                                                                textDecoration:
                                                                  "none",
                                                              }}
                                                            >
                                                              <ListItem
                                                                button
                                                                className={
                                                                  getPathnameMenu(
                                                                    value4.label
                                                                  ) ===
                                                                    value4.label
                                                                    ? "sidebar-is-selected-color"
                                                                    : "sidebar-is-not-selected-color"
                                                                }
                                                                style={{
                                                                  paddingTop: 0,
                                                                  paddingBottom: 0,
                                                                  paddingLeft: 30,
                                                                  height: 25,
                                                                }}
                                                              >
                                                                <ListItemIcon
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    justifyContent:
                                                                      "right",
                                                                    paddingRight: 10,
                                                                    minWidth: 25,
                                                                  }}
                                                                >
                                                                  <div
                                                                    className={
                                                                      getPathnameMenu(
                                                                        value4.label
                                                                      ) ===
                                                                        value4.label
                                                                        ? "sidebar-list-sub-header-dot-selected"
                                                                        : "sidebar-list-sub-header-dot"
                                                                    }
                                                                  />
                                                                </ListItemIcon>
                                                                <ListItemText>
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        ".8rem",
                                                                      fontWeight:
                                                                        getPathnameMenu(
                                                                          value4.label
                                                                        ) ===
                                                                        value4.label &&
                                                                        600,
                                                                      // textDecoration:
                                                                      //   getPathnameMenu(
                                                                      //     value3.label
                                                                      //   ) ===
                                                                      //     value3.label &&
                                                                      //   "underline",
                                                                    }}
                                                                  >
                                                                    {
                                                                      value4.label
                                                                    }
                                                                  </span>
                                                                </ListItemText>
                                                              </ListItem>
                                                            </Link>
                                                          )
                                                        )}
                                                      </List>
                                                    </Collapse>
                                                  )}
                                              </Collapse>
                                            );
                                          }
                                        )}
                                    </React.Fragment>
                                  )}
                                </React.Fragment>
                              );
                            })
                          }
                        </List>
                        <Divider style={{ margin: "0 25px" }} />
                      </React.Fragment>
                    );
                  })}
                <List style={{ padding: "10px 10px 0 10px", marginBottom: 20 }}>
                  <ListItem button onClick={onLogout}>
                    <ListItemIcon
                      style={{
                        display: "flex",
                        justifyContent: "right",
                        paddingRight: 10,
                        minWidth: 25,
                      }}
                    >
                      <div className="sidebar-is-not-selected-bg-color">
                        <img
                          loading="lazy"
                          src={LogoutIcon}
                          alt="logout-icon"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </ListItemIcon>
                    <ListItemText>
                      <span style={{ fontSize: 14, color: "#797783" }}>
                        Log out{" "}
                        {logoutLoading && (
                          <CircularProgress
                            color="secondary"
                            size={12}
                            style={{ marginLeft: 10 }}
                          />
                        )}
                      </span>
                    </ListItemText>
                  </ListItem>
                </List>
              </div>
            ) : (
              <div style={{ margin: "20px 0", overflowY: "auto" }}>
                {menu &&
                  menu.length > 0 &&
                  menu.map((value, key) => {
                    return (
                      <React.Fragment key={key}>
                        {value.menu &&
                          value.menu.length > 0 &&
                          value.menu.map((value2, key2) => {
                            return (
                              <React.Fragment key={key2}>
                                {value2 && value2.length > 0 ? (
                                  <React.Fragment>
                                    {value2.map((value2Value, value2Key) => {
                                      return (
                                        <React.Fragment key={value2Key}>
                                          {value2Value.sub_menu &&
                                            value2Value.sub_menu.length > 0 ? (
                                            <PopupState
                                              variant="popover"
                                              popupId={`${value2Value.label}${value2Key}`}
                                            >
                                              {(popupState) => (
                                                <div>
                                                  <Tooltip
                                                    title={value2Value.label}
                                                  >
                                                    <Button
                                                      {...bindTrigger(
                                                        popupState
                                                      )}
                                                      style={{ width: "100%" }}
                                                    >
                                                      <div
                                                        className={
                                                          getPathnameSubMenu() ===
                                                            value2Value.label
                                                            ? "sidebar-toggled-is-selected-bg-color"
                                                            : "sidebar-toggled-is-not-selected-bg-color"
                                                        }
                                                      >
                                                        {setMenuToggledIcon(
                                                          value2Value.label,
                                                          getPathnameSubMenu() ===
                                                          value2Value.label &&
                                                          "brightness(0) saturate(100%) invert(31%) sepia(49%) saturate(597%) hue-rotate(215deg) brightness(88%) contrast(102%)"
                                                        )}
                                                      </div>
                                                    </Button>
                                                  </Tooltip>
                                                  <Menu
                                                    {...bindMenu(popupState)}
                                                    anchorOrigin={{
                                                      vertical: "center",
                                                      horizontal: "right",
                                                    }}
                                                    transformOrigin={{
                                                      vertical: "center",
                                                      horizontal: "left",
                                                    }}
                                                  >
                                                    {value2Value.sub_menu &&
                                                      value2Value.sub_menu
                                                        .length > 0 &&
                                                      value2Value.sub_menu.map(
                                                        (value3, key3) => {
                                                          return (
                                                            <Link
                                                              to={setPathnameSubMenu(
                                                                value3.label
                                                              )}
                                                              key={key3}
                                                              style={{
                                                                textDecoration:
                                                                  "none",
                                                                color:
                                                                  "inherit",
                                                              }}
                                                            >
                                                              <MenuItem
                                                                onClick={
                                                                  popupState.close
                                                                }
                                                                style={{
                                                                  color:
                                                                    getPathnameMenu() ===
                                                                    value3.label &&
                                                                    "#7964b5",
                                                                }}
                                                              >
                                                                {value3.label}
                                                              </MenuItem>
                                                            </Link>
                                                          );
                                                        }
                                                      )}
                                                  </Menu>
                                                </div>
                                              )}
                                            </PopupState>
                                          ) : (
                                            <Link
                                              to={setPathnameMenu(
                                                value2Value.label
                                              )}
                                              style={{
                                                textDecoration: "none",
                                                color: "inherit",
                                              }}
                                            >
                                              <Tooltip
                                                title={value2Value.label}
                                              >
                                                <Button
                                                  style={{
                                                    width: "100%",
                                                    marginTop: 10,
                                                  }}
                                                >
                                                  <div
                                                    className={
                                                      getPathnameMenu() ===
                                                        value2Value.label
                                                        ? "sidebar-toggled-is-selected-bg-color"
                                                        : "sidebar-toggled-is-not-selected-bg-color"
                                                    }
                                                  >
                                                    {setMenuToggledIcon(
                                                      value2Value.label,
                                                      getPathnameMenu() ===
                                                      value2Value.label &&
                                                      "brightness(0) saturate(100%) invert(31%) sepia(49%) saturate(597%) hue-rotate(215deg) brightness(88%) contrast(102%)"
                                                    )}
                                                  </div>
                                                </Button>
                                              </Tooltip>
                                            </Link>
                                          )}
                                        </React.Fragment>
                                      );
                                    })}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment key={value2}>
                                    {value2.sub_menu &&
                                      value2.sub_menu.length > 0 ? (
                                      <PopupState
                                        variant="popover"
                                        popupId={`${value2.label}${key2}`}
                                      >
                                        {(popupState) => (
                                          <div>
                                            <Tooltip title={value2.label}>
                                              <Button
                                                {...bindTrigger(popupState)}
                                                style={{ width: "100%" }}
                                              >
                                                <div
                                                  className={
                                                    getPathnameSubMenu() ===
                                                      value2.label
                                                      ? "sidebar-toggled-is-selected-bg-color"
                                                      : "sidebar-toggled-is-not-selected-bg-color"
                                                  }
                                                >
                                                  {setMenuToggledIcon(
                                                    value2.label,
                                                    getPathnameSubMenu() ===
                                                    value2.label &&
                                                    "brightness(0) saturate(100%) invert(31%) sepia(49%) saturate(597%) hue-rotate(215deg) brightness(88%) contrast(102%)"
                                                  )}
                                                </div>
                                              </Button>
                                            </Tooltip>
                                            <Menu
                                              {...bindMenu(popupState)}
                                              anchorOrigin={{
                                                vertical: "center",
                                                horizontal: "right",
                                              }}
                                              transformOrigin={{
                                                vertical: "center",
                                                horizontal: "left",
                                              }}
                                            >
                                              {value2.sub_menu &&
                                                value2.sub_menu.length > 0 &&
                                                value2.sub_menu.map(
                                                  (value3, key3) => {
                                                    if (
                                                      value3?.sub_level &&
                                                      value3?.sub_level
                                                        ?.length > 0
                                                    ) {
                                                      return (
                                                        <PopupState
                                                          variant="popover"
                                                          popupId={`${value3.label}${key3}`}
                                                          key={key3}
                                                        >
                                                          {(popupState3) => (
                                                            <MenuItem
                                                              {...bindTrigger(
                                                                popupState3
                                                              )}
                                                              // onClick={() => {
                                                              //   console.log(
                                                              //     popupState3
                                                              //   );
                                                              //   if (
                                                              //     popupState3.isOpen
                                                              //   ) {
                                                              //     popupState3.close();
                                                              //     return;
                                                              //   }

                                                              //   bindTrigger(
                                                              //     popupState3
                                                              //   ).onClick();
                                                              //   // popupState3.open();
                                                              // }}
                                                              style={{
                                                                color:
                                                                  getPathnameMenu() ===
                                                                  value3.label &&
                                                                  "#7964b5",
                                                              }}
                                                            >
                                                              <Stack
                                                                direction="row"
                                                                alignItems="center"
                                                                spacing={1}
                                                              >
                                                                {value3.label}
                                                              </Stack>

                                                              <Menu
                                                                {...bindMenu(
                                                                  popupState3
                                                                )}
                                                                anchorOrigin={{
                                                                  vertical:
                                                                    "center",
                                                                  horizontal:
                                                                    "right",
                                                                }}
                                                                transformOrigin={{
                                                                  vertical:
                                                                    "center",
                                                                  horizontal:
                                                                    "left",
                                                                }}
                                                              >
                                                                {value3.sub_level.map(
                                                                  (
                                                                    value4,
                                                                    key4
                                                                  ) => (
                                                                    <Link
                                                                      to={setPathnameSubMenu(
                                                                        value4.label
                                                                      )}
                                                                      key={key4}
                                                                      style={{
                                                                        textDecoration:
                                                                          "none",
                                                                        color:
                                                                          "inherit",
                                                                      }}
                                                                    >
                                                                      <MenuItem
                                                                        onClick={() => {
                                                                          popupState.close();
                                                                          popupState3.close();
                                                                        }}
                                                                        style={{
                                                                          color:
                                                                            getPathnameMenu() ===
                                                                            value4.label &&
                                                                            "#7964b5",
                                                                        }}
                                                                      >
                                                                        {
                                                                          value4.label
                                                                        }
                                                                      </MenuItem>
                                                                    </Link>
                                                                  )
                                                                )}
                                                              </Menu>
                                                            </MenuItem>
                                                          )}
                                                        </PopupState>
                                                      );
                                                    }

                                                    return (
                                                      <Link
                                                        to={setPathnameSubMenu(
                                                          value3.label
                                                        )}
                                                        key={key3}
                                                        style={{
                                                          textDecoration:
                                                            "none",
                                                          color: "inherit",
                                                        }}
                                                      >
                                                        <MenuItem
                                                          onClick={
                                                            popupState.close
                                                          }
                                                          style={{
                                                            color:
                                                              getPathnameMenu() ===
                                                              value3.label &&
                                                              "#7964b5",
                                                          }}
                                                        >
                                                          {value3.label}
                                                        </MenuItem>
                                                      </Link>
                                                    );
                                                  }
                                                )}
                                            </Menu>
                                          </div>
                                        )}
                                      </PopupState>
                                    ) : (
                                      <Link
                                        to={setPathnameMenu(value2.label)}
                                        style={{
                                          textDecoration: "none",
                                          color: "inherit",
                                        }}
                                      >
                                        <Tooltip title={value2.label}>
                                          <Button style={{ width: "100%" }}>
                                            <div
                                              className={
                                                getPathnameMenu() ===
                                                  value2.label
                                                  ? "sidebar-toggled-is-selected-bg-color"
                                                  : "sidebar-toggled-is-not-selected-bg-color"
                                              }
                                            >
                                              {setMenuToggledIcon(
                                                value2.label,
                                                getPathnameMenu() ===
                                                value2.label &&
                                                "brightness(0) saturate(100%) invert(31%) sepia(49%) saturate(597%) hue-rotate(215deg) brightness(88%) contrast(102%)"
                                              )}
                                            </div>
                                          </Button>
                                        </Tooltip>
                                      </Link>
                                    )}
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            );
                          })}
                        <div style={{ margin: "10px 25px" }} />
                      </React.Fragment>
                    );
                  })}
                {/* <div style={{ margin: "10px 25px" }} /> */}
                <Tooltip title="Log out">
                  <Button style={{ width: "100%" }} onClick={onLogout}>
                    <div className="sidebar-toggled-is-not-selected-bg-color">
                      <img
                        loading="lazy"
                        src={LogoutIcon}
                        alt="logout-icon"
                        style={{ width: 25, margin: "0 auto" }}
                      />
                    </div>
                  </Button>
                </Tooltip>
              </div>
            )}
          </Drawer>

          {/*drawer mobile responsive */}
          <MuiDrawer
            open={mobileDrawerOpen}
            className="sidebar-menu-drawer-mobile"
          >
            <DrawerHeader className="sidebar-drawer-header">
              <img
                loading="lazy"
                src={logo_client_white}
                alt="logo-white"
                style={{ width: "100%" }}
              />
            </DrawerHeader>
            <Divider />
            <div style={{ overflowY: "auto" }}>
              {menu &&
                menu.length > 0 &&
                menu.map((value, key) => {
                  return (
                    <React.Fragment key={key}>
                      <List
                        style={{
                          padding: key === 0 ? "0 10px 10px 10px" : "20px 10px",
                          marginTop: key === 0 && 20,
                        }}
                        subheader={
                          <ListSubheader
                            disableSticky
                            component="div"
                            id={`nested-list-subheader${key}`}
                            className="sidebar-list-sub-header"
                          >
                            {value.label.toUpperCase()}
                          </ListSubheader>
                        }
                      >
                        {
                          // * main menu
                          value.menu &&
                          value.menu.length > 0 &&
                          value.menu.map((value2, key2) => {
                            return (
                              <React.Fragment key={key2}>
                                {value2 && value2.length > 0 ? (
                                  <React.Fragment>
                                    {value2.map((value2Value, value2Key) => {
                                      return (
                                        <React.Fragment key={value2Key}>
                                          {
                                            // * sub menu
                                            value2Value.sub_menu &&
                                              value2Value.sub_menu.length >
                                              0 ? (
                                              <ListItem
                                                button
                                                className={
                                                  getPathnameSubMenu() ===
                                                    value2Value.label
                                                    ? "sidebar-is-selected-color"
                                                    : "sidebar-is-not-selected-color"
                                                }
                                                onClick={() =>
                                                  value2Value.sub_menu
                                                    .length > 0 &&
                                                  onSubMenuClick(
                                                    value2Value.label
                                                  )
                                                }
                                              >
                                                <ListItemIcon
                                                  style={{ minWidth: 25 }}
                                                >
                                                  <div
                                                    className={
                                                      getPathnameSubMenu() ===
                                                        value2Value.label
                                                        ? "sidebar-is-selected-bg-color"
                                                        : "sidebar-is-not-selected-bg-color"
                                                    }
                                                  >
                                                    {setMenuIcon(
                                                      value2Value.label,
                                                      getPathnameSubMenu() ===
                                                      value2Value.label &&
                                                      "brightness(0) saturate(100%) invert(31%) sepia(49%) saturate(597%) hue-rotate(215deg) brightness(88%) contrast(102%)"
                                                    )}
                                                  </div>
                                                </ListItemIcon>
                                                <ListItemText>
                                                  <span
                                                    style={{
                                                      fontSize: ".8rem",
                                                    }}
                                                  >
                                                    {value2Value.label}
                                                  </span>
                                                </ListItemText>
                                                {value2Value.sub_menu.length >
                                                  0 &&
                                                  (subMenuOpen.includes(
                                                    value2Value.label
                                                  ) ? (
                                                    <img
                                                      loading="lazy"
                                                      src={ExpandLessIcon}
                                                      alt="expand-less-icon"
                                                    />
                                                  ) : (
                                                    <img
                                                      loading="lazy"
                                                      src={ExpandMoreIcon}
                                                      alt="expand-more-icon"
                                                    />
                                                  ))}
                                              </ListItem>
                                            ) : (
                                              <Link
                                                to={setPathnameMenu(
                                                  value2Value.label
                                                )}
                                                style={{
                                                  textDecoration: "none",
                                                }}
                                              >
                                                <ListItem
                                                  button
                                                  className={
                                                    getPathnameMenu(
                                                      value2Value.label
                                                    ) === value2Value.label
                                                      ? "sidebar-is-selected-color"
                                                      : "sidebar-is-not-selected-color"
                                                  }
                                                >
                                                  <ListItemIcon
                                                    style={{
                                                      minWidth: 25,
                                                    }}
                                                  >
                                                    <div
                                                      className={
                                                        getPathnameMenu(
                                                          value2Value.label
                                                        ) ===
                                                          value2Value.label
                                                          ? "sidebar-is-selected-bg-color"
                                                          : "sidebar-is-not-selected-bg-color"
                                                      }
                                                    >
                                                      {setMenuIcon(
                                                        value2Value.label,
                                                        getPathnameMenu(
                                                          value2Value.label
                                                        ) ===
                                                        value2Value.label &&
                                                        "brightness(0) saturate(100%) invert(31%) sepia(49%) saturate(597%) hue-rotate(215deg) brightness(88%) contrast(102%)"
                                                      )}
                                                    </div>
                                                  </ListItemIcon>
                                                  <ListItemText>
                                                    <span
                                                      style={{
                                                        fontSize: ".8rem",
                                                      }}
                                                    >
                                                      {value2Value.label}
                                                    </span>
                                                  </ListItemText>
                                                </ListItem>
                                              </Link>
                                            )
                                          }
                                          {value2Value.sub_menu &&
                                            value2Value.sub_menu.length > 0 &&
                                            value2Value.sub_menu.map(
                                              (value3, key3) => {
                                                return (
                                                  <Collapse
                                                    in={subMenuOpen.includes(
                                                      value2Value.label
                                                    )}
                                                    timeout="auto"
                                                    unmountOnExit
                                                    key={key3}
                                                  >
                                                    <List
                                                      component="div"
                                                      disablePadding
                                                    >
                                                      <Link
                                                        to={setPathnameSubMenu(
                                                          value3.label
                                                        )}
                                                        style={{
                                                          textDecoration:
                                                            "none",
                                                        }}
                                                      >
                                                        <ListItem
                                                          button
                                                          className={
                                                            getPathnameMenu(
                                                              value3.label
                                                            ) === value3.label
                                                              ? "sidebar-is-selected-color"
                                                              : "sidebar-is-not-selected-color"
                                                          }
                                                          style={{
                                                            paddingTop: 0,
                                                            paddingBottom: 0,
                                                            height: 25,
                                                          }}
                                                        >
                                                          <ListItemIcon
                                                            style={{
                                                              display: "flex",
                                                              justifyContent:
                                                                "right",
                                                              paddingRight: 10,
                                                              minWidth: 25,
                                                            }}
                                                          >
                                                            <div
                                                              className={
                                                                getPathnameMenu(
                                                                  value3.label
                                                                ) ===
                                                                  value3.label
                                                                  ? "sidebar-list-sub-header-dot-selected"
                                                                  : "sidebar-list-sub-header-dot"
                                                              }
                                                            />
                                                          </ListItemIcon>
                                                          <ListItemText>
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  ".8rem",
                                                                fontWeight:
                                                                  getPathnameMenu(
                                                                    value3.label
                                                                  ) ===
                                                                  value3.label &&
                                                                  600,
                                                                // textDecoration:
                                                                //   getPathnameMenu(
                                                                //     value3.label
                                                                //   ) ===
                                                                //     value3.label &&
                                                                //   "underline",
                                                              }}
                                                            >
                                                              {value3.label}
                                                            </span>
                                                          </ListItemText>
                                                        </ListItem>
                                                      </Link>
                                                    </List>
                                                  </Collapse>
                                                );
                                              }
                                            )}
                                        </React.Fragment>
                                      );
                                    })}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    {value2.sub_menu &&
                                      value2.sub_menu.length > 0 ? (
                                      <ListItem
                                        button
                                        className={
                                          getPathnameSubMenu() ===
                                            value2.label
                                            ? "sidebar-is-selected-color"
                                            : "sidebar-is-not-selected-color"
                                        }
                                        onClick={() =>
                                          value2.sub_menu.length > 0 &&
                                          onSubMenuClick(value2.label)
                                        }
                                      >
                                        <ListItemIcon
                                          style={{ minWidth: 38 }}
                                        >
                                          <div
                                            className={
                                              getPathnameSubMenu() ===
                                                value2.label
                                                ? "sidebar-is-selected-bg-color"
                                                : "sidebar-is-not-selected-bg-color"
                                            }
                                          >
                                            {setMenuIcon(
                                              value2.label,
                                              getPathnameSubMenu() ===
                                              value2.label &&
                                              "brightness(0) saturate(100%) invert(31%) sepia(49%) saturate(597%) hue-rotate(215deg) brightness(88%) contrast(102%)"
                                            )}
                                          </div>
                                        </ListItemIcon>
                                        <ListItemText>
                                          <span style={{ fontSize: ".8rem" }}>
                                            {value2.label}
                                          </span>
                                        </ListItemText>
                                        {value2.sub_menu.length > 0 &&
                                          (subMenuOpen.includes(
                                            value2.label
                                          ) ? (
                                            <img
                                              loading="lazy"
                                              src={ExpandLessIcon}
                                              alt="expand-less-icon"
                                            />
                                          ) : (
                                            <img
                                              loading="lazy"
                                              src={ExpandMoreIcon}
                                              alt="expand-more-icon"
                                            />
                                          ))}
                                      </ListItem>
                                    ) : (
                                      <Link
                                        to={setPathnameMenu(value2.label)}
                                        style={{ textDecoration: "none" }}
                                      >
                                        <ListItem
                                          button
                                          className={
                                            getPathnameMenu(value2.label) ===
                                              value2.label
                                              ? "sidebar-is-selected-color"
                                              : "sidebar-is-not-selected-color"
                                          }
                                        >
                                          <ListItemIcon
                                            style={{ minWidth: 38 }}
                                          >
                                            <div
                                              className={
                                                getPathnameMenu(
                                                  value2.label
                                                ) === value2.label
                                                  ? "sidebar-is-selected-bg-color"
                                                  : "sidebar-is-not-selected-bg-color"
                                              }
                                            >
                                              {setMenuIcon(
                                                value2.label,
                                                getPathnameMenu(
                                                  value2.label
                                                ) === value2.label &&
                                                "brightness(0) saturate(100%) invert(31%) sepia(49%) saturate(597%) hue-rotate(215deg) brightness(88%) contrast(102%)"
                                              )}
                                            </div>
                                          </ListItemIcon>
                                          <ListItemText>
                                            <span
                                              style={{ fontSize: ".8rem" }}
                                            >
                                              {value2.label}
                                            </span>
                                          </ListItemText>
                                        </ListItem>
                                      </Link>
                                    )}
                                    {value2.sub_menu &&
                                      value2.sub_menu.length > 0 &&
                                      value2.sub_menu.map((value3, key3) => {
                                        return (
                                          <Collapse
                                            in={subMenuOpen.includes(
                                              value2.label
                                            )}
                                            timeout="auto"
                                            unmountOnExit
                                            key={key3}
                                          >
                                            <List
                                              component="div"
                                              disablePadding
                                            >
                                              {value3.sub_level &&
                                                value3.sub_level.length > 0 ? (
                                                <ListItem
                                                  onClick={() =>
                                                    value3.sub_level.length >
                                                    0 &&
                                                    onSubLevelClick(
                                                      value3.label
                                                    )
                                                  }
                                                  button
                                                  className={
                                                    getPathnameSubLevelMenu(
                                                      value3.label
                                                    ) === value3.label
                                                      ? "sidebar-is-selected-color"
                                                      : "sidebar-is-not-selected-color"
                                                  }
                                                  style={{
                                                    paddingTop: 0,
                                                    paddingBottom: 0,
                                                    height: 25,
                                                  }}
                                                >
                                                  <ListItemIcon
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "right",
                                                      paddingRight: 10,
                                                      minWidth: 25,
                                                    }}
                                                  >
                                                    <div
                                                      className={
                                                        getPathnameMenu(
                                                          value3.label
                                                        ) === value3.label
                                                          ? "sidebar-list-sub-header-dot-selected"
                                                          : "sidebar-list-sub-header-dot"
                                                      }
                                                    />
                                                  </ListItemIcon>
                                                  <ListItemText>
                                                    <span
                                                      style={{
                                                        fontSize: ".8rem",
                                                        fontWeight:
                                                          getPathnameMenu(
                                                            value3.label
                                                          ) ===
                                                          value3.label &&
                                                          600,
                                                        // textDecoration:
                                                        //   getPathnameMenu(
                                                        //     value3.label
                                                        //   ) ===
                                                        //     value3.label &&
                                                        //   "underline",
                                                      }}
                                                    >
                                                      {value3.label}
                                                    </span>
                                                  </ListItemText>
                                                  {value3.sub_level.length >
                                                    0 &&
                                                    (subLevelOpen.includes(
                                                      value3.label
                                                    ) ? (
                                                      <img
                                                        loading="lazy"
                                                        src={ExpandLessIcon}
                                                        alt="expand-less-icon"
                                                      />
                                                    ) : (
                                                      <img
                                                        loading="lazy"
                                                        src={ExpandMoreIcon}
                                                        alt="expand-more-icon"
                                                      />
                                                    ))}
                                                </ListItem>
                                              ) : (
                                                <Link
                                                  to={setPathnameSubMenu(
                                                    value3.label
                                                  )}
                                                  style={{
                                                    textDecoration: "none",
                                                  }}
                                                >
                                                  <ListItem
                                                    button
                                                    className={
                                                      getPathnameMenu(
                                                        value3.label
                                                      ) === value3.label
                                                        ? "sidebar-is-selected-color"
                                                        : "sidebar-is-not-selected-color"
                                                    }
                                                    style={{
                                                      paddingTop: 0,
                                                      paddingBottom: 0,
                                                      height: 25,
                                                    }}
                                                  >
                                                    <ListItemIcon
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "right",
                                                        paddingRight: 10,
                                                        minWidth: 25,
                                                      }}
                                                    >
                                                      <div
                                                        className={
                                                          getPathnameMenu(
                                                            value3.label
                                                          ) === value3.label
                                                            ? "sidebar-list-sub-header-dot-selected"
                                                            : "sidebar-list-sub-header-dot"
                                                        }
                                                      />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                      <span
                                                        style={{
                                                          fontSize: ".8rem",
                                                          fontWeight:
                                                            getPathnameMenu(
                                                              value3.label
                                                            ) ===
                                                            value3.label &&
                                                            600,
                                                          // textDecoration:
                                                          //   getPathnameMenu(
                                                          //     value3.label
                                                          //   ) ===
                                                          //     value3.label &&
                                                          //   "underline",
                                                        }}
                                                      >
                                                        {value3.label}
                                                      </span>
                                                    </ListItemText>
                                                  </ListItem>
                                                </Link>
                                              )}
                                            </List>
                                            {value3.sub_level &&
                                              value3.sub_level.length > 0 && (
                                                <Collapse
                                                  in={subLevelOpen.includes(
                                                    value3.label
                                                  )}
                                                  timeout="auto"
                                                  unmountOnExit
                                                >
                                                  <List
                                                    component="div"
                                                    disablePadding
                                                    sx={{
                                                      marginBottom: "10px",
                                                    }}
                                                  >
                                                    {value3.sub_level.map(
                                                      (value4, key4) => (
                                                        <Link
                                                          key={key4}
                                                          to={setPathnameSubMenu(
                                                            value4.label
                                                          )}
                                                          style={{
                                                            textDecoration:
                                                              "none",
                                                          }}
                                                        >
                                                          <ListItem
                                                            button
                                                            className={
                                                              getPathnameMenu(
                                                                value4.label
                                                              ) ===
                                                                value4.label
                                                                ? "sidebar-is-selected-color"
                                                                : "sidebar-is-not-selected-color"
                                                            }
                                                            style={{
                                                              paddingTop: 0,
                                                              paddingBottom: 0,
                                                              paddingLeft: 30,
                                                              height: 25,
                                                            }}
                                                          >
                                                            <ListItemIcon
                                                              style={{
                                                                display:
                                                                  "flex",
                                                                justifyContent:
                                                                  "right",
                                                                paddingRight: 10,
                                                                minWidth: 25,
                                                              }}
                                                            >
                                                              <div
                                                                className={
                                                                  getPathnameMenu(
                                                                    value4.label
                                                                  ) ===
                                                                    value4.label
                                                                    ? "sidebar-list-sub-header-dot-selected"
                                                                    : "sidebar-list-sub-header-dot"
                                                                }
                                                              />
                                                            </ListItemIcon>
                                                            <ListItemText>
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    ".8rem",
                                                                  fontWeight:
                                                                    getPathnameMenu(
                                                                      value4.label
                                                                    ) ===
                                                                    value4.label &&
                                                                    600,
                                                                  // textDecoration:
                                                                  //   getPathnameMenu(
                                                                  //     value3.label
                                                                  //   ) ===
                                                                  //     value3.label &&
                                                                  //   "underline",
                                                                }}
                                                              >
                                                                {value4.label}
                                                              </span>
                                                            </ListItemText>
                                                          </ListItem>
                                                        </Link>
                                                      )
                                                    )}
                                                  </List>
                                                </Collapse>
                                              )}
                                          </Collapse>
                                        );
                                      })}
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            );
                          })
                        }
                      </List>
                      <Divider style={{ margin: "0 25px" }} />
                    </React.Fragment>
                  );
                })}
              <List style={{ padding: "10px 10px 0 10px", marginBottom: 20 }}>
                <ListItem button onClick={onLogout}>
                  <ListItemIcon
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      paddingRight: 10,
                      minWidth: 25,
                    }}
                  >
                    <div className="sidebar-is-not-selected-bg-color">
                      <img
                        loading="lazy"
                        src={LogoutIcon}
                        alt="logout-icon"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </ListItemIcon>
                  <ListItemText>
                    <span style={{ fontSize: ".8rem", color: "#797783" }}>
                      Log out{" "}
                      {logoutLoading && (
                        <CircularProgress
                          color="secondary"
                          size={12}
                          style={{ marginLeft: 10 }}
                        />
                      )}
                    </span>
                  </ListItemText>
                </ListItem>
              </List>
            </div>
          </MuiDrawer>
          <Box
            sx={{
              flexGrow: 1,
              paddingBottom: faqScreen ? 0 : 3,
              paddingLeft: faqScreen ? 0 : 3,
              paddingRight: faqScreen ? 0 : 3,
              paddingTop:
                notifBanner &&
                  notificationList &&
                  notificationList.notifications.length > 0 &&
                  next_link.includes("ApplicantSearch")
                  ? "72px"
                  : (faqScreen ? 0 : 3),
              overflow: "auto",
            }}
          >
            <DrawerHeader />
            {children}
          </Box>
        </Box>

        <style jsx="true" global="false">
          {`
            .MuiPopover-paper {
              filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.1)) !important;
              border: none !important;
            }
          `}
        </style>
      </div>
      {window.location.href.indexOf("client/dashboard") > -1 && (
        <div
          className="dashboard-ads-container"
          style={{
            position: "sticky",
            bottom: 0,
            width: "100%",
            zIndex: 1000,
          }}
        >
          <DashboardAds clientAction={clientAction} controller={controller} />
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ client }) => ({
  client,
});

const mapDispatchToProps = (dispatch) => ({
  clientAction: bindActionCreators(clientAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
