import React from "react";
import CircularProgress from '@mui/material/CircularProgress';

export const LoadingComponent = ({ isLoading, error }) => {
	console.log("ERROR COMPONENT", error);
	
	if (isLoading) {
		return (
			<div className="" style={{ display: "flex", alignItems: "center", minHeight: "93vh" }}>
				<div className="" style={{ width: "100%", textAlign: "center" }}>
					<CircularProgress color="secondary" />
				</div>
			</div>
		);
	} else if (error) {
		return (
			<div className="" style={{ display: "flex", alignItems: "center", minHeight: "93vh" }}>
				<div className="" style={{ width: "100%", textAlign: "center" }}>
					Sorry, there was a problem loading the page.
				</div>
			</div>
		);
	} else {
		return null;
	}
};