import Swal from 'sweetalert2';

export const showSwal = ({ title, text, icon = "error", isHtml = false, confirmButtonText = 'CLOSE', redirectPath }) => {
  isHtml ?
    Swal.fire({
      title,
      html: text,
      icon,
      confirmButtonText,
      confirmButtonColor: '#55428A'
    }).then(() => redirectPath ? window.location.href = redirectPath : null) :
    Swal.fire({
      title,
      text,
      icon,
      confirmButtonText,
      confirmButtonColor: '#55428A'
    }).then(() => redirectPath ? window.location.href = redirectPath : null);
};

export const showIconWithText = ({title, text, imageUrl, imageWidth = 400, imageHeight = 200, redirectPath = null, confirmButtonText = "OK"}) => {
  Swal.fire({
    title,
    html: text,
    imageUrl,
    imageWidth,
    imageHeight,
    imageAlt: 'Custom Image',
    confirmButtonText,
    confirmButtonColor: "#55428A",
  }).then(() => redirectPath ? window.location.href = redirectPath : null)
};