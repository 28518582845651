import React, { useRef, useState, useEffect } from "react";
import { useClickAway } from "react-use";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Triangle from "../../../assets/floatingTriangle.png";
import { onMessageListener } from "../../../config/firebase";
import { getToken } from "../../../utils/auth";
import { showError } from "../../../utils/api_resp_display";
import NotifCard from "./NotifCard";

import { useGetNotifications } from "../../../query/notifications/NotificationQueries";
import "./_index.scss";

const Notifications = ({ clientAction }) => {
  const notificationRef = useRef(null);
  const [showNotifications, setNotifications] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [notificationCount, setNotificationCount] = useState(null);

  const token = getToken();

  const { data, refetch, isError, error } = useGetNotifications(token);

  const handleShowNotifications = () => setNotifications(!showNotifications);

  useClickAway(notificationRef, () => handleShowNotifications());

  onMessageListener()
    .then((payload) => {
      refetch();
    })
    .catch((err) => console.log("failed: ", err));

  if (isError) showError(error);

  return (
    <>
      <div className="notifications" style={{ position: "relative" }}>
        {/* {data && data.counts > 0 && (
          <span className="notifications-count">{data.counts}</span>
        )} */}
        <Tooltip title="Notification" placement="bottom">
          <Badge
            color="secondary"
            badgeContent={data?.counts ?? 0}
            style={{ cursor: "pointer" }}
            onClick={handleShowNotifications}
          >
            <NotificationsNoneIcon className="header-top-icons" />
          </Badge>
        </Tooltip>
        {showNotifications && (
          <div ref={notificationRef} className="notifications-wrapper">
            <div className="notifications-container">
              <img src={Triangle} alt="triangle" />
              <Box
                sx={(theme) => ({
                  padding: "3px 0px 10px",
                  backgroundColor: theme.palette.brandColor[500],
                  borderRadius: "10px",
                  border: "2px solid #fff",
                  boxShadow: 2,
                })}
              >
                <Typography
                  variant="body1"
                  sx={{
                    padding: "5px 10px",
                    fontSize: ".75rem",
                    color: "#fff",
                    fontWeight: "600",
                  }}
                >
                  NOTIFICATIONS
                </Typography>
                <div className="notifications">
                  {data && data.notifications.length > 0 ? (
                    data.notifications.map((item, i) => (
                      <NotifCard
                        key={i}
                        item={item}
                        setNotifications={setNotifications}
                        index={i}
                        setNotificationList={setNotificationList}
                        setNotificationCount={setNotificationCount}
                        notificationList={data.notifications}
                        clientAction={clientAction}
                        refetch={refetch}
                      />
                    ))
                  ) : (
                    <p className="no-notifications">No Notifications</p>
                  )}
                  {/* <NotifCard /> */}
                </div>
              </Box>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Notifications;
