import { combineReducers } from "redux";
import auth from "./authReducer";
import client from "./clientReducer";

const rootReducer = combineReducers({
  auth,
  client
});

export default rootReducer;
