import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { Typography } from "@mui/material";

import { getToken } from "../../../utils/auth";
import { showError } from "../../../utils/api_resp_display";

const usersDummy = [
  { name: "Letty", position: 30, initial: "L", isActive: 0 },
  { name: "John Gayares", position: 60, initial: "JG", isActive: 0 },
  { name: "Maita Teodoro", position: 90, initial: "MT", isActive: 0 },
  { name: "Herbert Cipriano", position: 120, initial: "HC", isActive: 0 },
  { name: "Ralph Olarte", position: 0, initial: "RO", isActive: 0 },
  { name: "Mr Boogey", position: 0, initial: "MB", isActive: 0 },
];

const positions = [30, 60, 90, 120];

const colors = ["#109cf1", "#FFB946", "#f53a29", "#24ad63"];

const ActiveUsers = ({ clientAction }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const open = Boolean(anchorEl);

  const controller = new AbortController();

  const token = getToken();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  async function getUsers() {
    try {
      setLoading(true);
      const result = await clientAction.ClientAPI_Get_Available_Users(
        null,
        null,
        { "x-access-token": token },
        controller
      );

      setUsers(
        result.data.data
          .filter((item) => item.availability === 1)
          .map((item, i) => ({
            name: `${item.first_name} ${item.last_name}`,
            position: positions[i] || 0,
            initial:
              item.first_name.charAt(0).toUpperCase() +
              item.last_name.charAt(0).toUpperCase(),
            isActive: item.availability,
          }))
      );
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getUsers();

    return () => controller.abort();
  }, []);

  return (
    <>
      <Stack direction="row" alignItems={"center"} sx={{ marginLeft: 3 }}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          // marginLeft={users.length > 0 ? "190px" : "20px"}
          marginRight={"20px"}
        >
          <Tooltip title="Add user">
            <Link style={{ textDecoration: "none" }} to="/client/account/user">
              <IconButton
                sx={{
                  border: "2px solid #fff",
                  width: 36,
                  height: 36,
                  "&:hover": {
                    backgroundColor: "#463672",
                  },
                }}
              >
                <PersonAddAltIcon
                  sx={{
                    color: "#fff",
                    fontSize: "1.4rem",
                  }}
                />
              </IconButton>
            </Link>
          </Tooltip>
        </Stack>
        {users.length > 0 && (
          <Box sx={{ position: "relative", width: 180, height: 36 }}>
            {users.length > 4 && (
              <Avatar
                onClick={handleClick}
                sx={{
                  backgroundColor: "#605f68",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: 35,
                  height: 35,
                  fontSize: 12,
                  cursor: "pointer",
                  border: "2px solid white",
                  "&:hover": {
                    transform: "scale(1.05)",
                    zIndex: 10,
                  },
                }}
              >
                {`+${users.length - 4}`}
              </Avatar>
            )}
            {users.map((item, i) =>
              i < 4 ? (
                <Tooltip title={item.name}>
                  <Avatar
                    sx={{
                      backgroundColor: colors[i],
                      position: "absolute",
                      top: 0,
                      left: item.position,
                      width: 35,
                      height: 35,
                      fontSize: 12,
                      border: "2px solid white",
                      "&:hover": {
                        transform: "scale(1.05)",
                        zIndex: 10,
                      },
                    }}
                  >
                    {item.initial}
                  </Avatar>
                </Tooltip>
              ) : null
            )}
          </Box>
        )}
      </Stack>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        {users.map((item, i) =>
          i > 3 ? (
            <Stack
              direction="row"
              // spacing={1}
              alignItems="center"
              sx={{
                paddingX: 2,
                paddingY: 0.5,
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: colors[Math.floor(Math.random() * 4)],
                  width: 35,
                  height: 35,
                  fontSize: 12,
                  border: "2px solid white",
                }}
              >
                {item.initial}
              </Avatar>
              <Typography
                sx={{
                  fontSize: ".9rem",
                  color: "#55428a",
                  fontWeight: 500,
                }}
              >
                {item.name}
              </Typography>
            </Stack>
          ) : null
        )}
      </Menu>
    </>
  );
};

export default ActiveUsers;
