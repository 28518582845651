import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Swal from 'sweetalert2';
import SuitCaseIcon from "../assets/suitcase-icon.png";
import { removeUserSession } from './auth';
import { showIconWithText } from './swal';

export const showError = (error, redirectPath) => {
  const text = error?.response?.data?.data?.error || error?.response?.data?.message || "Something went wrong. Please try again later.";

  if (error?.message !== "canceled") {
    if (text === "Unauthenticated." || text === "Invalid token.") {
      Swal.fire({
        title: 'Session Expired',
        text: 'Please log in again',
        icon: 'info',
        confirmButtonText: 'OK',
        confirmButtonColor: '#55428A'
      }).then(() => {
        window.location.href = "/login"
        removeUserSession();
      });
    } else if (text === "There is an existing CLIENT associated with this email address") {
      showIconWithText({
        title: "System Notice",
        text: `<div>There is an existing CLIENT associated with this email address. Please check your email you may be able to retrieve your account</div>`,
        imageUrl: SuitCaseIcon,
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonText: "CLOSE",
      })
    } else if (text === "You dont have billing for this month.") {
      Swal.fire({
        title: '',
        text,
        icon: 'info',
        confirmButtonText: 'OK',
        confirmButtonColor: '#55428A'
      }).then(() => redirectPath ? window.location.href = redirectPath : null);
    } else {
      Swal.fire({
        customClass: {
          container: "zIndex10000",
          icon: "error-icon",
          title: "error-title",
          htmlContainer: "error-html-container",
          actions: "error-actions",
          popup: "error-popup",
        },
        title: 'Oops! Something went wrong!',
        html:
          error?.response?.data instanceof Blob &&
            error?.response?.status === 422
            ? "This module is currently under maintenance. Please use CSV or EXCEL to generate the list."
            : (error?.response?.status < 400 && error?.response?.status > 499)
              ? `An unexpected error has been encountered. Kindly contact the development team at <a href="mailto:itsupport@satelliteteams.com">itsupport@satelliteteams.com</a>.<br></br>`
              : text,
        icon: (error?.response?.status < 400 && error?.response?.status > 499) ? "error" : "warning",
        confirmButtonText: "View Details",
        confirmButtonColor: "transparent",
        cancelButtonText: "Dismiss",
        cancelButtonColor: "#9bd675",
        background: "#534388",
        showCancelButton: true,
        showConfirmButton:
          error?.response?.data instanceof Blob &&
            error?.response?.status === 422
            ? false
            : (error?.response?.status < 400 && error?.response?.status > 499) ? true : false,
        preConfirm: async () => {
          return Swal.showValidationMessage(text);
        },
      }).then(() => redirectPath ? window.location.href = redirectPath : null);
    }
  }
};

export const showErrorAlert = ({ error, title }) => {
  const description = error?.response?.data?.data?.error || error?.response?.data?.message || "Something went wrong. Please try again later.";

  return (
    <Alert severity="error">
      {title && <AlertTitle>{title}</AlertTitle>}
      {description}
    </Alert>
  )
};

export const showInfo = (text, redirectPath, isHtml = false) => {
  isHtml ?
    Swal.fire({
      title: "",
      html: text,
      icon: "info",
      confirmButtonText: "OK",
      confirmButtonColor: "#55428A",
    }).then(() => redirectPath ? window.location.href = redirectPath : null) :
    Swal.fire({
      title: "",
      text,
      icon: "info",
      confirmButtonText: "OK",
      confirmButtonColor: "#55428A",
    }).then(() => redirectPath ? window.location.href = redirectPath : null);
};

export const showSuccess = (text, redirectPath, isHtml = false) => {
  isHtml ?
    Swal.fire({
      title: "",
      html: text,
      icon: "success",
      confirmButtonText: "OK",
      confirmButtonColor: "#55428A",
    }).then(() => redirectPath ? window.location.href = redirectPath : null) :
    Swal.fire({
      title: "",
      text,
      icon: "success",
      confirmButtonText: "OK",
      confirmButtonColor: "#55428A",
    }).then(() => redirectPath ? window.location.href = redirectPath : null);
};