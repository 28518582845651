import { useQuery, useMutation } from "react-query";

import { Client } from "../../api/api";

export const queryKeyNotificaions = "notifications";

export function useGetNotifications(token) {
  return useQuery(
    queryKeyNotificaions,
    async (props) => {
      try {
        const res = await Client(null, null, {
          "x-access-token": token,
        }).API_Notification_Get();
        const notifWithoutSow = res.data.data.filter(
          (item) => !item.title.includes("SOW")
        );
        const newNotif = notifWithoutSow.filter((item) => item.visit === 0);

        const notifs = notifWithoutSow.map((item) => ({
          ...item,
          title:
            item.title === "Online proficiency assessment"
              ? "English proficiency"
              : item.title,
        }));

        // return { notifications: notifs, counts: newNotif.length };
        // console.log(
        //   `${window.location.protocol}//${window.location.hostname}/client/account/profile/about`
        // );
        const MAIN_API = process.env.REACT_APP_MAIN;
        return {
          notifications: [
            {
              action: 0,
              created_at: new Date(),
              data: null,
              deleted: 0,
              highlight: "Please complete your profile information.",
              id: 9619,
              link: MAIN_API.includes("staging")
                ? `https://client-staging.satelliteteams.com/client/account/profile/about`
                : `https://client.satelliteteams.com/client/account/profile/about`,
              sent: 1,
              title: "Profile Information",
              updated_at: "2023-04-26T00:01:05.000000Z",
              user_id: 5264,
              visit: 0,
            },
          ],
          counts: 1,
        };
      } catch (error) {
        throw error;
      }
    },
    {
      refetchOnMount: false,
      // initialData: {
      //   notifications: [],
      //   counts: 0,
      // },
    }
  );
}

export function useVisitedNotification() {
  async function mutateFn(props) {
    try {
      await Client(props.itemId, null, {
        "x-access-token": props.token,
      }).API_Notification_Visit();
    } catch (error) {
      throw new Error(error);
    }
  }

  return useMutation(mutateFn);
}
