export const getUser = () => {
  const userStr = localStorage.getItem('user');

  if (userStr) return JSON.parse(userStr);
  else return null;
}

export const getToken = () => {
  return localStorage.getItem("token") || null;
}

export const setUserSession = (token, user) => {
  localStorage.setItem('token', token);
  localStorage.setItem('user', JSON.stringify(user));
}

export const setUserNextLink = (link) => {
  const userStr = localStorage.getItem('user');
  const parseUser = JSON.parse(userStr);

  localStorage.setItem('user', JSON.stringify({...parseUser, next_link: link}));
}

export const updateUserProperty = ({property, value}) => {
  const userStr = localStorage.getItem('user');
  const parseUser = JSON.parse(userStr);

  localStorage.setItem('user', JSON.stringify({...parseUser, [property]: value}));
}

export const sessionCount = (count) => {
  localStorage.setItem('count', '1');
}

export const getCount = () => {
  return localStorage.getItem("count");
}

export const removeUserSession = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('count');
}

export const appId = "SYS";
export const roleId = 2;

export const setUserNextLinkString = () => {
  const userStr = localStorage.getItem('user');
  const parseUser = JSON.parse(userStr);

  const links = {
    "ApplicantSearch": "/client/management/candidate-search",
    "TermsAndCondition": "/terms-and-condition",
    "JobPreferences": "/job",
  };

  return links[parseUser?.next_link] || '';
}