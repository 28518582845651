import { showError } from "../../../utils/api_resp_display";
import { getToken } from "../../../utils/auth";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import moment from "moment";

import { useVisitedNotification } from "../../../query/notifications/NotificationQueries";

const NotifCard = ({
  item,
  setShowOnboard,
  setNotifications,
  index,
  setNotificationList,
  setNotificationCount,
  notificationList,
  clientAction,
  refetch,
}) => {
  const token = getToken();

  const { mutateAsync, isError, error } = useVisitedNotification();

  const handleClickHere = async () => {
    await mutateAsync({ itemId: item?.id, token });
    refetch();
    setNotifications(false);
    console.log(item.link);
    window.location.replace(item.link);
    // clientAction
    //   .ClientAPI_Notification_Visit(item?.id, null, { "x-access-token": token })
    //   .then((res) => {
    //     window.location.href = item.link;
    //     setNotifications(false);
    //     if (item.visit === 0 || item.visit === "0") {
    //       const listInstance = [...notificationList];
    //       const itemInstance = { ...item, visit: 1 };

    //       listInstance.splice(index, 1, itemInstance);

    //       setNotificationList(listInstance);
    //       setNotificationCount((prev) => prev - 1);
    //     }
    //   })
    //   .catch((err) => {
    //     showError(err);
    //   });
  };

  if (isError) showError(error);

  return (
    <div
      key={index}
      className={`notif-card-container ${
        item?.visit === 0 ? "new-notif-card" : ""
      }`}
      style={{ marginBottom: index + 1 !== notificationList.length && "10px" }}
      onClick={handleClickHere}
    >
      <Grid container alignItems="center">
        <Grid item xs={9}>
          <h5>{item.title}</h5>
          <p>{item.highlight}</p>
        </Grid>
        <Grid item xs={3}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"flex-end"}
          >
            <Typography
              variant="body1"
              sx={(theme) => ({
                fontSize: ".55rem !important",
                fontWeight: `${600} !important`,
                marginRight: "3px",
              })}
            >
              {moment(item.created_at).fromNow()}
            </Typography>
            <Box
              sx={(theme) => ({
                width: "6px",
                height: "6px",
                borderRadius: "100%",
                backgroundColor:
                  theme.palette.notification[
                    item.visit === 0 ? "unread-dot" : "read-dot"
                  ],
              })}
            />
          </Stack>
        </Grid>
      </Grid>
      {/* <span>{item?.title}</span>
      <div>
        <i style={{ fontSize: 12, color: "black" }}>
          {moment(item?.created_at).format("MM/DD/YYYY hh:mm:ss")}
        </i>
      </div>
      <p>- {item?.highlight}</p>
      <span onClick={handleClickHere}>Click here</span> */}
    </div>
  );
};

export default NotifCard;
