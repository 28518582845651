import * as types from "../types/authType";
import { getToken } from "../../utils/auth";

export function saveAuthSuccess(auth) {
	return {
		type: types.SAVE_AUTH_SUCCESS,
		auth,
	};
}

export function checkAuthSuccess(auth) {
	return {
		type: types.CHECK_AUTH_SUCCESS,
		auth,
	};
}

export function checkAuth() {
	if (getToken() === "") {
		return checkAuthSuccess({});
	}

	return checkAuthSuccess({ token: getToken() });
}