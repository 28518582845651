import React, { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import Snackbar from "@mui/material/Snackbar";
import Fade from "@mui/material/Fade";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InfoIcon from "@mui/icons-material/Info";
import RightContentBG from "../../../assets/home-background.png";
import { showError } from "../../../utils/api_resp_display";
import { getToken } from "../../../utils/auth";

const mainContainer = {
  width: "100%",
  height: "100%",
  background: `url(${RightContentBG}) left bottom no-repeat`,
  backgroundSize: "cover",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  gap: "8%",
  padding: "15px 30px",
};

export const DashboardAds = ({ clientAction, controller }) => {
  const [referralData, setReferralData] = useState({});
  const [referralLoading, setReferralLoading] = useState(false);
  const [copiedSnackbar, setCopiedSnackbar] = useState(false);

  const token = getToken();

  // useEffect(() => {
  //   setReferralLoading(true);
  //   clientAction
  //     .ClientAPI_Referral_Get(
  //       null,
  //       null,
  //       { "x-access-token": token },
  //       controller
  //     )
  //     .then((res) => {
  //       setReferralData(res.data?.data);
  //       setReferralLoading(false);
  //     })
  //     .catch((err) => {
  //       setReferralLoading(false);
  //       showError(err);
  //     });

  //   return () => controller.abort();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [clientAction, token]);

  const onCopy = (link) => {
    navigator.clipboard.writeText(link);
    setCopiedSnackbar(true);
  };

  return (
    <React.Fragment>
      <div
        id="content-box-shadow"
        className="dashboard-ads-container-box"
        style={mainContainer}
      >
        <div>
          <div className="dashboard-ads-text">
            {/* Do you need work force, &nbsp; */}
            {/* <span className="dashboard-ads-text-white">Create Jobs Now!</span>
            <br />
            Click the link and provide necessary information. */}
            Need more talent? Click the button <br /> to create a job ad today!
          </div>

          <div
            className="dashboard-ads-job-requirements-button"
            onClick={() =>
              (window.location.href =
                "/client/management/candidate-management/job-requirement")
            }
          >
            Post A Job Ad
          </div>
        </div>

        {/* <div className="dashboard-ads-text">
          Need to put Ads,
          <br />
          <div className="dashboard-ads-text-white">Apply Here.</div>
          This pops-up for 20
          <br />
          seconds slideshow.
        </div>

        <div className="dashboard-ads-ads-requirement-button">
          Ads Requirement
        </div> */}

        <div>
          <div className="dashboard-ads-text">
            {/* On going search
            <br />
            for <span className="dashboard-ads-text-white">Applicants</span> */}
            Find your next remote <br /> rockstar hire here.
          </div>

          <div
            className="dashboard-ads-view-applicants-button"
            onClick={() =>
              // (window.location.href =
              //   "/client/management/candidate-list/my-interview")
              (window.location.href =
                "/client/management/candidate-management/candidate-search")
            }
          >
            Explore Candidate Profiles
          </div>
        </div>

        <div>
          <div className="dashboard-ads-text">
            {/* Refer a Friend
            <div className="dashboard-ads-text-white">
              & Get Rewarded
            </div> */}
            Earn incredible rewards with <br /> our Referral Program!
          </div>

          <div className="dashboard-ads-refer-applicant-button">
            {referralLoading ? (
              <Skeleton animation="wave" />
            ) : (
              // referralData?.link && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                // onClick={() => onCopy(referralData.link)}
                onClick={() =>
                  (window.location.href =
                    "/client/management/candidate-management/candidate-search")
                }
              >
                Refer A Friend Today
                {/* <ContentCopyIcon
                  style={{ color: "white", fontSize: 16, marginLeft: 10 }}
                /> */}
              </div>
              // )
            )}
          </div>
        </div>

        <Snackbar
          autoHideDuration={1000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={copiedSnackbar}
          onClose={() => setCopiedSnackbar(false)}
          TransitionComponent={Fade}
          message={
            <div style={{ display: "flex", alignItems: "center" }}>
              <InfoIcon />
              &emsp;Copied
            </div>
          }
        />
      </div>
    </React.Fragment>
  );
};
