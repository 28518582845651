import React, { useEffect } from "react";
import { QueryClientProvider } from "react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Loadable from "react-loadable";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import ReactGA from "react-ga4";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as clientAction from "../src/redux/actions/clientAction";
import { LoadingComponent } from "./components/general/LoadingComponent";
import ScrollTopComponent from "./components/general/ScrollTopComponent";
import Layout from "./components/client/layout/Layout";
import { getToken, getUser } from "./utils/auth";
import { generateToken } from "./config/firebase";
import { theme } from "./utils/theme";
import Swal from "sweetalert2";
import useDetectDevice from "./utils/useDetectDevice";
import ipadImage from "./assets/ipad.png";
import androidImage from "./assets/android-phone.png";
import getOnAppStoreImage from "./assets/header/get-on-app-store.png";
import getOnPlayStoreImage from "./assets/header/get-on-google-play.png";
import MobileVersionImage from "./assets/mobile-version-image.png";

import { queryClient } from "./config/queryClient";
// import { hasReferralCode } from "./utils/url_helpers";

const Redirecting = Loadable({
  loader: () => import("./components/general/RedirectComponent"),
  loading: LoadingComponent,
});
const Maintenance = Loadable({
  loader: () => import("./components/maintenance/Maintenance"),
  loading: LoadingComponent,
});
const ComingSoon = Loadable({
  loader: () => import("./components/comingsoon/ComingSoon"),
  loading: LoadingComponent,
});
const UnderConstruction = Loadable({
  loader: () => import("./components/underconstruction/UnderConstruction"),
  loading: LoadingComponent,
});
const GoogleCalendarRedirect = Loadable({
  loader: () => import("./components/redirect/GoogleCalendarRedirect"),
  loading: LoadingComponent,
});

const ZoomScreen = Loadable({
  loader: () => import("./components/client/zoom/Zoom"),
  loading: LoadingComponent,
});

const TermsAndConditionScreen = Loadable({
  loader: () => import("./components/user/terms/TermsAndCondition"),
  loading: LoadingComponent,
});
const JobScreen = Loadable({
  loader: () => import("./components/user/job/Job"),
  loading: LoadingComponent,
});

const LoginScreen = Loadable({
  loader: () => import("./components/user/login/Login"),
  loading: LoadingComponent,
});
const RegisterScreen = Loadable({
  loader: () => import("./components/user/registration/Registration"),
  loading: LoadingComponent,
});
const ForgotPasswordScreen = Loadable({
  loader: () => import("./components/user/forgotpassword/ForgotPassword"),
  loading: LoadingComponent,
});
const ResetPasswordScreen = Loadable({
  loader: () => import("./components/user/resetpassword/ResetPassword"),
  loading: LoadingComponent,
});
const ResumeScreen = Loadable({
  loader: () => import("./components/user/resume/Resume"),
  loading: LoadingComponent,
});
const ViewResumeScreen = Loadable({
  loader: () => import("./components/user/resume/ViewResume"),
  loading: LoadingComponent,
});

const DashboardScreen = Loadable({
  loader: () => import("./components/client/dashboard/Dashboard"),
  loading: LoadingComponent,
});

// const ProfileScreen = Loadable({ loader: () => import("./components/client/account/profile/Profile"), loading: LoadingComponent });
const ProfileScreen = Loadable({
  loader: () => import("./components/client/account/profile/ProfileModified"),
  loading: LoadingComponent,
});
const UserScreen = Loadable({
  loader: () => import("./components/client/account/user/User"),
  loading: LoadingComponent,
});
const RolesScreen = Loadable({
  loader: () => import("./components/client/account/roles/Roles"),
  loading: LoadingComponent,
});

const RequestScreen = Loadable({
  loader: () => import("./components/client/request/Request"),
  loading: LoadingComponent,
});

const ReferralsScreen = Loadable({
  loader: () => import("./components/client/referrals/Referrals"),
  loading: LoadingComponent,
});

// const JobRequirementScreen = Loadable({ loader: () => import("./components/client/management/jobRequirement/JobRequirement"), loading: LoadingComponent });
const CandidateSearchScreen = Loadable({
  loader: () =>
    import("./components/client/management/candidateSearch/CandidateSearch"),
  loading: LoadingComponent,
});
const CandidateListScreen = Loadable({
  loader: () =>
    import("./components/client/management/candidateList/CandidateList"),
  loading: LoadingComponent,
});
const EmployeeScreen = Loadable({
  loader: () => import("./components/client/management/employee/Employee"),
  loading: LoadingComponent,
});
const EmployeeInformationTabsScreen = Loadable({
  loader: () =>
    import("./components/client/management/employee/EmployeeInformationTabs"),
  loading: LoadingComponent,
});

const BillingScreen = Loadable({
  loader: () => import("./components/client/billingAndInvoices/Billing"),
  loading: LoadingComponent,
});
const InvoiceScreen = Loadable({
  loader: () => import("./components/client/billingAndInvoices/Invoice"),
  loading: LoadingComponent,
});

const PaymentScreen = Loadable({
  loader: () => import("./components/client/payment/Payment"),
  loading: LoadingComponent,
});

const JobRequirementsScreen = Loadable({
  loader: () => import("./components/client/jobRequirements/JobRequirements"),
  loading: LoadingComponent,
});

// const DummyJobRequirementsScreen = Loadable({
//   loader: () =>
//     import("./components/client/jobRequirements/JobRequirements-old"),
//   loading: LoadingComponent,
// });

const ReferralRedirectScreen = Loadable({
  loader: () => import("./components/client/referrals/ReferralRedirect"),
  loading: LoadingComponent,
});

const SystemConfigScreen = Loadable({
  loader: () => import("./components/client/systemConfig/SystemConfiguration"),
  loading: LoadingComponent,
});

const EmployeeDepartment = Loadable({
  loader: () => import("./components/client/systemConfig/Department"),
  loading: LoadingComponent,
});

const UpdateCredential = Loadable({
  loader: () => import("./components/user/updateCredential/UpdateCredential"),
  loading: LoadingComponent,
});

const FAQScreen = Loadable({
  loader: () => import("./components/client/faq/FAQ"),
  loading: LoadingComponent,
});

const history = createBrowserHistory();

const hasToken = getToken();
const { menu } = getUser() || [];

// const theme = createTheme({
//   typography: {
//     fontFamily: "'Poppins', sans-serif",
//   },
// });

const REACT_APP_GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
const REACT_APP_MAINTENANCE = process.env.REACT_APP_MAINTENANCE;
const REACT_APP_LAUNCHING = process.env.REACT_APP_LAUNCHING;

const Routes = ({ store, clientAction }) => {
  const currentUser = getUser();

  const os = useDetectDevice("os");
  const browser = useDetectDevice("browser");

  if (REACT_APP_MAINTENANCE === "1") {
    history.push("/maintenance");
  }

  if (REACT_APP_LAUNCHING === "1") {
    history.push("/coming-soon");
  }

  if (currentUser && currentUser.next_link) {
    if (currentUser.next_link === "TermsAndCondition")
      history.push("/terms-and-condition");
    if (currentUser.next_link === "JobPreferences") history.push("/job");
    if (currentUser.next_link === "ApplicantSearch") history.push("/client/management/candidate-management/candidate-search");
  }

  generateToken(clientAction.setNotificationToken);

  useEffect(() => {
    if (
      os?.name === "iPad" ||
      os?.name === "iPhone" ||
      os?.name === "Android"
    ) {
      Swal.fire({
        html: `<p>Hello! To continue using the Satellite Teams Client Website, please download the ${
          os?.name === "Android" ? "Android" : "IOS"
        } application version.</p>
        <p>It's easier to use in a mobile device. <br /> Don't worry, it has the same functions with the web version.</p>`,
        iconHtml: `<img src=${MobileVersionImage} alt="ipad" />`,
        customClass: {
          htmlContainer: "swal-custom-text",
          icon: "swal-custom-icon",
        },
        allowOutsideClick: false,
        // toast: true,
        // position: "bottom-start",
        background: "#f0eef7",
        confirmButtonText: `Download app on ${
          os?.name === "Android" ? "GooglePlay" : "AppStore"
        }`,
        confirmButtonColor: "#55428a",
        preConfirm: () => {
          window.open(
            os?.name === "Android"
              ? "https://play.google.com/store/apps/details?id=com.satelliteteams.hiring.staging"
              : "https://apps.apple.com/app/satellite-jobs/id1612191019",
            "_blank"
          );
          return false; // Prevent confirmed
        },
        preDeny: () => {
          window.open(
            os?.name === "Android"
              ? "https://play.google.com/store/apps/details?id=com.satelliteteams.hiring.staging"
              : "https://apps.apple.com/app/satellite-jobs/id1612191019",
            "_blank"
          );
          return false; // Prevent denied
        },
      }).then(() => {
        window.open(
          os?.name === "Android"
            ? "https://play.google.com/store/apps/details?id=com.satelliteteams.hiring.staging"
            : "https://apps.apple.com/app/satellite-jobs/id1612191019",
          "_blank"
        );
      });
    }

    //     if (os?.name === "Android") {
    //       Swal.fire({
    //         html: `<p>You're currently using an Android device.</p>
    //         <p>For better user experience, kindly download our app on the PlayStore.</p>
    //         <a href="https://play.google.com/store/apps/details?id=com.satelliteteams.hiring.staging" target="_blank"
    // >        <img src=${getOnPlayStoreImage} width="120" height="36" alt="download playstore"/></a>`,
    //         iconHtml: `<img src=${androidImage} width="60" height="60" alt="ipad" />`,
    //         customClass: {
    //           htmlContainer: "swal-custom-text",
    //           icon: "swal-custom-icon",
    //         },
    //         toast: true,
    //         position: "bottom-start",
    //         showConfirmButton: false,
    //         background: "#f0eef7",
    //         timer: 500,
    //         didOpen: () => {
    //           Swal.stopTimer();
    //         },
    //       });
    //     }
  }, [browser, os]);

  useEffect(() => {
    ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_ID);
  }, []);

  useEffect(() => {
    clientAction.ClientAPI_Company_Profile_Get();
  }, [clientAction]);

  const setPathnameMenu = (label) => {
    switch (label) {
      case "Dashboard":
        return "/client/dashboard";
      case "Request List":
        return "/client/request-list/:selectedTab";
      case "Referrals":
        return "/client/referrals";
      case "Payment method":
        return "/client/payment-method";
      case "Reports":
        return "/client/reports";
      case "System Configuration":
        return "/client/system-configuration/:selectedTab";
      case "FAQ":
        return "/client/faq";
      default:
        return "/";
    }
  };

  const setPathnameSubMenu = (label) => {
    switch (label) {
      case "Profile":
        return "/client/account/profile/:selectedTab";
      case "User":
        return "/client/account/user";
      case "Roles":
        return "/client/account/roles";
      case "Job requirement":
        return "/client/management/candidate-management/job-requirement";
      case "Candidate search":
        return "/client/management/candidate-management/candidate-search";
      case "Candidate list":
        return "/client/management/candidate-management/candidate-list/:selectedTab";
      case "Request List":
        return "/client/management/employee-management/request-list/:selectedTab";
      case "Employee":
        return "/client/management/employee-management/employee/:selectedTab";
      case "Employee Department":
        return "/client/management/employee-management/department";
      case "Billing":
        return "/client/billingAndInvoices/billing";
      case "Invoices History":
        return "/client/billingAndInvoices/invoices-history";
      default:
        return "/";
    }
  };

  const setPathnameComponent = (label) => {
    switch (label) {
      case "Dashboard":
        return DashboardScreen;
      case "Request List":
        return RequestScreen;
      case "Referrals":
        return ReferralsScreen;
      case "Payment method":
        return PaymentScreen;
      case "System Configuration":
        return SystemConfigScreen;
      case "FAQ":
        return FAQScreen;
      default:
        return UnderConstruction;
    }
  };

  const setPathnameSubComponent = (label) => {
    switch (label) {
      case "Profile":
        return ProfileScreen;
      case "User":
        return UserScreen;
      case "Roles":
        return RolesScreen;
      case "Job requirement":
        return JobRequirementsScreen;
      case "Candidate search":
        return CandidateSearchScreen;
      case "Candidate list":
        return CandidateListScreen;
      case "Request List":
        return RequestScreen;
      case "Employee":
        return EmployeeScreen;
      case "Employee Department":
        return EmployeeDepartment;
      case "Billing":
        return BillingScreen;
      case "Invoices History":
        return InvoiceScreen;
      default:
        return UnderConstruction;
    }
  };

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_ID}>
          <ThemeProvider theme={theme}>
            <Provider store={store}>
              <Router history={history}>
                <SnackbarProvider maxSnack={3} autoHideDuration={2500}>
                  <Switch>
                    <PublicRoutes path="/login" component={LoginScreen} exact />
                    <PublicRoutes
                      path="/register"
                      component={RegisterScreen}
                      exact
                    />
                    <PublicRoutes
                      path="/register/:code"
                      component={RegisterScreen}
                      exact
                    />
                    <PublicRoutes
                      path="/update-credential"
                      component={UpdateCredential}
                      exact
                    />
                    <PublicRoutes
                      path="/forgot-password"
                      component={ForgotPasswordScreen}
                      exact
                    />
                    <PublicRoutes
                      path="/reset-password/:code"
                      component={ResetPasswordScreen}
                      exact
                    />
                    <Route
                      path="/resume/:resumeId/:positionId/:resumeToken"
                      component={ResumeScreen}
                      exact
                    />
                    <Route
                      path="/resume/:resumeId/:positionId"
                      component={ViewResumeScreen}
                      exact
                    />
                    <PublicRoutes
                      path="/terms"
                      showType={"terms"}
                      component={TermsAndConditionScreen}
                      withoutLayout
                      exact
                    />
                    <PublicRoutes
                      path="/data-privacy"
                      showType={"data-privacy"}
                      component={TermsAndConditionScreen}
                      withoutLayout
                      exact
                    />
                    <Route
                      path="/referral/:code/:resumeId/:positionId"
                      component={ReferralRedirectScreen}
                      exact
                    />

                    {/* dummy route */}
                    {/* <Route
                      path="/dummy-job"
                      component={DummyJobRequirementsScreen}
                      exact
                    /> */}

                    {/* <PrivateRoutes
                  path="/terms-and-condition"
                  component={TermsAndConditionScreen}
                  withoutLayout
                  exact
                /> */}
                    <PrivateRoutes
                      path="/job"
                      component={JobScreen}
                      withoutLayout
                      exact
                    />

                    {menu?.map(
                      (value, key) =>
                        value?.menu?.length > 0 &&
                        value?.menu.map((value2, key2) => {
                          if (value2 && value2.length > 0) {
                            return value2.map((value3, key3) => {
                              if (
                                value3?.sub_menu &&
                                value3?.sub_menu?.length > 0
                              ) {
                                return value3.sub_menu.map((value4, key4) => {
                                  if (value4?.label === "Profile") {
                                    return (
                                      <PrivateRoutes
                                        path={setPathnameSubMenu(value4.label)}
                                        component={setPathnameSubComponent(
                                          value4.label
                                        )}
                                        permissions={value4?.permission}
                                        exact
                                      />
                                    );
                                  }

                                  return (
                                    <PrivateRoutes
                                      path={setPathnameSubMenu(value4.label)}
                                      component={setPathnameSubComponent(
                                        value4.label
                                      )}
                                      permissions={value4?.permission}
                                      exact
                                    />
                                  );
                                });
                              } else {
                                return (
                                  <PrivateRoutes
                                    path={setPathnameMenu(value3.label)}
                                    component={setPathnameComponent(
                                      value3.label
                                    )}
                                    permissions={value3?.permission}
                                    exact
                                  />
                                );
                              }
                            });
                          } else {
                            if (
                              value2?.sub_menu &&
                              value2?.sub_menu?.length > 0
                            ) {
                              return value2.sub_menu.map((value3, key4) => {
                                if (
                                  value3?.sub_level &&
                                  value3?.sub_level.length > 0
                                ) {
                                  return value3?.sub_level.map(
                                    (value4, key5) => {
                                      return (
                                        <PrivateRoutes
                                          key={key5}
                                          path={setPathnameSubMenu(
                                            value4.label
                                          )}
                                          component={setPathnameSubComponent(
                                            value4.label
                                          )}
                                          permissions={value4?.permission}
                                          exact
                                        />
                                      );
                                    }
                                  );
                                }

                                return (
                                  <PrivateRoutes
                                    path={setPathnameSubMenu(value3.label)}
                                    component={setPathnameSubComponent(
                                      value3.label
                                    )}
                                    permissions={value3?.permission}
                                    exact
                                  />
                                );
                              });
                            } else {
                              return (
                                <PrivateRoutes
                                  path={setPathnameMenu(value2.label)}
                                  component={setPathnameComponent(value2.label)}
                                  permissions={value2?.permission}
                                  exact
                                />
                              );
                            }
                          }
                        })
                    )}

                    {/* zoom */}
                    <PrivateRoutes
                      path="/client/zoom/:meetingId/:id/:position_id/:meetingNumber/:password/:isHost"
                      component={ZoomScreen}
                      withoutLayout
                      exact
                    />

                    <PrivateRoutes
                      path={"/client/management/employee/:id/:selectedTab"}
                      component={EmployeeInformationTabsScreen}
                      exact
                    />

                    <PrivateRoutes
                      path={"/client/system-configuration/:selectedTab"}
                      component={SystemConfigScreen}
                      exact
                    />

                    <PrivateRoutes
                      path="/client/google-calendar"
                      component={GoogleCalendarRedirect}
                      withoutLayout
                    />

                    <PrivateRoutes
                      path="/client/faq"
                      component={FAQScreen}
                      exact
                    />

                    {/* general */}
                    {REACT_APP_MAINTENANCE === "1" ? (
                      <Route path="/maintenance" component={Maintenance} />
                    ) : null}
                    {REACT_APP_LAUNCHING === "1" ? (
                      <Route path="/coming-soon" component={ComingSoon} />
                    ) : null}

                    <Route path="/redirecting" component={Redirecting} />
                    <Redirect to="/login" component={Redirecting} />
                  </Switch>
                  <ScrollTopComponent />
                </SnackbarProvider>
              </Router>
            </Provider>
          </ThemeProvider>
        </GoogleOAuthProvider>
      </QueryClientProvider>
      <p
        style={{
          position: "fixed",
          bottom: -7,
          right: 5,
          fontSize: ".7rem",
          color: "#ccc",
        }}
      >
        {"v. " + process.env.REACT_APP_VERSION}
      </p>
    </>
  );
};

const PublicRoutes = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        hasToken ? (
          <Redirect to="/redirecting" />
        ) : (
          <Component
            {...props}
            showType={rest?.showType ? rest?.showType : false}
          />
        )
      }
    />
  );
};

const PrivateRoutes = ({
  component: Component,
  withoutLayout = false,
  permissions = [],
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (hasToken) {
          if (withoutLayout) {
            return <Component {...props} />;
          } else {
            return (
              <Layout>
                <Component permissions={permissions} {...props} />
              </Layout>
            );
          }
        } else {
          window.location.href = "/";
        }
      }}
    />
  );
};

const mapStateToProps = ({ client }) => ({
  client,
});

const mapDispatchToProps = (dispatch) => ({
  clientAction: bindActionCreators(clientAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
