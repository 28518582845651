import React, { useState, useRef } from "react";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";

import {
  useGetNotifications,
  useVisitedNotification,
} from "../../../query/notifications/NotificationQueries";

import { getToken } from "../../../utils/auth";

const NotificationBanner = ({ setNotifBanner }) => {
  const sliderRef = useRef();
  const [currentSlide, setCurrentSlide] = useState(0);
  const token = getToken();

  const { data, refetch } = useGetNotifications(token);
  const { mutateAsync } = useVisitedNotification();

  const handleClickHere = async (item, index) => {
    await mutateAsync({ itemId: item.id, token });
    refetch();
    window.location.replace(item.link);
  };

  return (
    <Box
      sx={{
        padding: "10px 20px 10px 30px",
        backgroundColor: "#F0EEF7",
        height: "48px",
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={1} width="100%">
          <Stack direction="row" alignItems="center" spacing={1}>
            <button
              onClick={() => sliderRef.current.previous()}
              style={{
                background: "transparent",
                zIndex: 5,
                cursor: "pointer",
                marginRight: 15,
                border: 0,
              }}
            >
              <ArrowBackIosIcon
                sx={(theme) => ({
                  color: "#000",
                  fontSize: ".95rem",
                })}
              />
            </button>
            <button
              style={{
                background: "transparent",
                zIndex: 5,
                cursor: "pointer",
                border: 0,
              }}
              onClick={() => sliderRef.current.next()}
            >
              <ArrowForwardIosIcon
                sx={(theme) => ({
                  color: "#000",
                  fontSize: ".95rem",
                })}
              />
            </button>
            <Box width={60}>
              <Typography
                variant="body1"
                sx={(theme) => ({
                  color: "#000",
                  fontSize: ".8rem",
                  fontWeight: 600,
                  marginRight: "10px",
                })}
              >
                {`${currentSlide + 1} of ${data?.notifications?.length}`}
              </Typography>
            </Box>
          </Stack>
          <Box
            sx={{
              height: "28px",
              width: "100%",
            }}
          >
            <Slider
              classNames={{
                slider: "custom-slider",
              }}
              duration={2000}
              // autoplay={3000}
              // infinite
              ref={sliderRef}
              previousButton={null}
              nextButton={null}
              onSlideChange={(data) => setCurrentSlide(data.slideIndex)}
              direction="vertical"
            >
              {data &&
                data.notifications.map((item, index) => (
                  <Stack
                    key={index}
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    sx={{
                      height: "100%",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={(theme) => ({
                        color: "#000",
                        fontSize: ".8rem",
                        fontWeight: 600,
                        marginRight: "10px",
                      })}
                    >
                      {`${item.title}`}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={(theme) => ({
                        color: "#000",
                        fontSize: ".75rem",
                        marginRight: "10px",
                      })}
                    >
                      {item.highlight}
                    </Typography>
                    {
                      <Typography
                        variant="body1"
                        component="span"
                        sx={(theme) => ({
                          background: "#9CD874",
                          padding: "5px 8px",
                          borderRadius: "5px",
                          fontSize: ".75rem",
                          color: "#fff",
                          cursor: "pointer",
                        })}
                        onClick={() => handleClickHere(item, index)}
                      >
                        Click here
                      </Typography>
                    }
                  </Stack>
                ))}
            </Slider>
          </Box>
        </Stack>
        <button
          onClick={() => setNotifBanner(false)}
          style={{
            background: "transparent",
            zIndex: 5,
            cursor: "pointer",
            border: 0,
          }}
        >
          <CloseIcon
            sx={(theme) => ({
              color: "#000",
              fontSize: "1.4rem",
            })}
          />
        </button>
      </Stack>
    </Box>
  );
};

export default NotificationBanner;
