import * as ClientType from "../types/clientType";
import { combineReducers } from "redux";

const companyProfile = (state = {}, action) => {
  switch (action.type) {
    case ClientType.GET_COMPANY_PROFILE:
      return action.data;
    case ClientType.FAILED_COMPANY_PROFILE:
      return {};
    default:
      return state;
  }
};

const companyProfileLoading = (state = false, action) => {
  switch (action.type) {
    case ClientType.LOADING_COMPANY_PROFILE:
      return true;
    case ClientType.GET_COMPANY_PROFILE:
    case ClientType.FAILED_COMPANY_PROFILE:
      return false;
    default:
      return state;
  }
};

const notificationToken = (state = "", action) => {
  switch (action.type) {
    case ClientType.NOTIFICATION_TOKEN:
      return action.data;
    default:
      return state;
  }
};

export default combineReducers({
  companyProfile,
  companyProfileLoading,
  notificationToken,
});
